/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import { MixType, RefType } from 'Type/Common.type';
import { noopFn } from 'Util/Common';

import {
    IMAGE_LOADED, IMAGE_LOADING, IMAGE_NOT_FOUND, IMAGE_NOT_SPECIFIED
} from './Image.config';

import './Image.style';

/**
 * Image component
 * Images are loaded only when they appear in a viewport
 * @class Image
 * @namespace Bodypwa/Component/Image/Component */
export class ImageComponent extends PureComponent {
    static propTypes = {
        isPlaceholder: PropTypes.bool,
        title: PropTypes.string,
        // eslint-disable-next-line react/boolean-prop-naming
        src: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool
        ]),
        style: PropTypes.shape({
            width: PropTypes.string,
            height: PropTypes.string
        }),
        alt: PropTypes.string,
        className: PropTypes.string,
        ratio: PropTypes.oneOf([
            '4x3',
            '16x9',
            'square',
            'custom'
        ]),
        wrapperSize: PropTypes.shape({
            height: PropTypes.string
        }),
        mix: MixType,
        imageRef: RefType,
        isPlain: PropTypes.bool,
        isCached: PropTypes.bool,
        // eslint-disable-next-line react/boolean-prop-naming
        showIsLoading: PropTypes.bool,
        imageCustomClass: PropTypes.string,
        dataAttributes: PropTypes.objectOf(PropTypes.string),
        // eslint-disable-next-line react/boolean-prop-naming
        noFade: PropTypes.bool.isRequired
    };

    static defaultProps = {
        src: '',
        alt: '',
        wrapperSize: {},
        style: {},
        title: null,
        isPlain: false,
        isPlaceholder: false,
        isCached: false,
        className: '',
        ratio: 'square',
        mix: {},
        showIsLoading: false,
        imageRef: noopFn,
        imageCustomClass: '',
        dataAttributes: {}
    };

    image = createRef();

    state = { imageStatus: IMAGE_LOADING };

    renderMap = {
        [IMAGE_LOADING]: this.renderLoadedImage.bind(this),
        [IMAGE_LOADED]: this.renderLoadedImage.bind(this)
    };

    onError = this.onError.bind(this);

    onLoad = this.onLoad.bind(this);

    componentDidMount() {
        this.onImageChange();
    }

    componentDidUpdate(prevProps) {
        const { src: prevSrc } = prevProps;
        const { src } = this.props;

        if (src !== prevSrc) {
            this.onImageChange();
        }
    }

    onImageChange() {
        const { src, isCached } = this.props;

        if (!src) {
            return this.setState({ imageStatus: IMAGE_NOT_SPECIFIED });
        }

        if (isCached) {
            return this.setState({ imageStatus: IMAGE_LOADED });
        }

        return this.setState({ imageStatus: IMAGE_LOADING });
    }

    onError() {
        this.setState({ imageStatus: IMAGE_NOT_FOUND });
    }

    onLoad() {
        this.setState({ imageStatus: IMAGE_LOADED });
    }

    renderImageNotFound() {
        if (navigator.onLine) {
            return (
                <span
                  className="absolute max-h-max w-full text-center m-auto start-0 text-black-normal text-p14"
                >
                    { __('Image not found') }
                </span>
            );
        }

        return <span block="Image" elem="Content" mods={ { isOffline: true } } />;
    }

    renderStyledImage() {
        const {
            alt,
            src,
            style,
            title,
            imageCustomClass,
            dataAttributes,
            noFade
        } = this.props;
        const { imageStatus } = this.state;

        // eslint-disable-next-line no-nested-ternary
        const fadeInClass = noFade ? '' : imageStatus === IMAGE_LOADED ? 'fade-in' : 'opacity-0';

        if (!src) {
            return null;
        }

        return (
            <img
              className={ `absolute start-0 w-full h-full top-0 object-center
                ${fadeInClass} ${imageCustomClass}` }
              src={ src || '' }
              alt={ alt }
              style={ style }
              title={ title }
              onLoad={ this.onLoad }
              onError={ this.onError }
              loading="lazy"
              { ...dataAttributes }
            />
        );
    }

    renderPlainImage() {
        const {
            alt,
            src,
            style,
            title,
            className,
            dataAttributes,
            imageCustomClass,
            noFade
        } = this.props;
        const { imageStatus } = this.state;

        // eslint-disable-next-line no-nested-ternary
        const fadeInClass = noFade ? '' : imageStatus === IMAGE_LOADED ? 'fade-in' : 'opacity-0';
        if (!src) {
            return null;
        }

        return (
            <img
              className={ `${fadeInClass} ${imageCustomClass} object-center` }
              block={ className }
              src={ src || '' }
              alt={ alt }
              style={ style }
              title={ title }
              onLoad={ this.onLoad }
              onError={ this.onError }
              loading="lazy"
              { ...dataAttributes }
            />
        );
    }

    renderImageNotSpecified() {
        return (
            <span
              className="absolute max-h-max w-full text-center m-auto start-0 text-black-normal text-p14"
            >
                { __('Image not specified') }
            </span>
        );
    }

    renderLoadedImage() {
        const { isPlain } = this.props;

        if (isPlain) {
            return this.renderPlainImage();
        }

        return this.renderStyledImage();
    }

    renderImage() {
        const { isPlaceholder } = this.props;
        const { imageStatus } = this.state;

        if (isPlaceholder) {
            return null;
        }

        const render = this.renderMap[imageStatus];

        if (!render) {
            return null;
        }

        return render();
    }

    renderLoader() {
        const { showIsLoading } = this.props;
        const { imageStatus } = this.state;

        if (imageStatus !== IMAGE_LOADING || !showIsLoading) {
            return null;
        }

        return (
            <div
              className="absolute w-full h-full bg-[var(--placeholder-image)] bg-[var(--placeholder-size)]
               animate-[var(--placeholder-animation)]"
            />
        );
    }

    render() {
        const {
            ratio,
            mix,
            isPlaceholder,
            wrapperSize,
            src,
            imageRef,
            className,
            isPlain
        } = this.props;

        const { imageStatus } = this.state;
        const ratioClass = {
            '16x9': 'pb-[56.25%]',
            '4x3': 'pb-[75%]',
            square: 'pb-[100%]'
        }[ratio] || '';

        const imageStatusClass = imageStatus.toLowerCase() === 'image_loading'
            ? 'bg-[var(--placeholder-image)] bg-[var(--placeholder-size)] after:content-[" "] after:bg-[var(--placeholder-image)] after:bg-[var(--placeholder-size)] after:w-full after:h-full after:[var(--placeholder-animation)] after:absolute z-[45]'
            : '';
        const placeholderClass = isPlaceholder ? 'bg-[var(--placeholder-image)] bg-[var(--placeholder-size)] after:content-[" "] after:bg-[var(--placeholder-image)] after:bg-[var(--placeholder-size)] after:w-full after:h-full after:[var(--placeholder-animation)] after:absolute z-[45]' : '';
        const hasSrc = !!src;
        const hasSrcClass = hasSrc ? 'bg-none' : '';
        const imageStatusLoaded = imageStatus === IMAGE_LOADED ? 'bg-none' : '';
        if (isPlain) {
            return this.renderImage();
        }

        return (
            <div
              className={ `inline-block w-full bg-[var(--image-default-background)]
                ${className} ${ratioClass} ${imageStatusClass} ${placeholderClass} ${hasSrcClass} ${imageStatusLoaded}` }
              ref={ imageRef }
              mix={ mix }
              style={ wrapperSize }
            >
                { this.renderImage() }
                { this.renderLoader() }
            </div>
        );
    }
}

export default ImageComponent;
