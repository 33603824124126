export const FOOTER_SKELETON_MOBILE = [
    {
        type: 'group',
        flexDirection: 'column',
        gap: 0,
        items: [
            { height: 297, marginBottom: 10 },
            { height: 637, marginBottom: 10 },
            { height: 88, marginBottom: 10 },
            { height: 428, marginBottom: 10 },
            { height: 527, marginBottom: 10 }
        ]
    }
];

export const FOOTER_SKELETON = [
    {
        type: 'group',
        flexDirection: 'column',
        gap: 0,
        items: [
            { height: 306, marginBottom: 32 },
            {
                type: 'group',
                gap: 10,
                maxWidth: 1336,
                columns: 4,
                marginBottom: 24,
                items: [
                    { height: 233 }
                ]
            },
            {
                type: 'group',
                gap: 20,
                maxWidth: 1336,
                height: 135,
                alignItems: 'center',
                columns: 4,
                marginBottom: 24,
                items: [
                    { height: 62 }
                ]
            },
            {
                type: 'group',
                gap: 20,
                maxWidth: 1336,
                alignItems: 'center',
                columns: 4,
                marginBottom: 24,
                items: [
                    { height: 276 }
                ]
            },
            { height: 96, marginBottom: 32 },
            { height: 247 }
        ]
    }
];
