/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Aleksejs Ivanovs <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { PRIVACY_POLICY_POPUP_ID } from 'Component/PrivacyPolicyPopup/PrivacyPolicyPopup.config';
import { showPopup } from 'Store/Popup/Popup.action';
import { ChildrenType } from 'Type/Common.type';

import PrivacyPolicyButtonComponent from './PrivacyPolicyButton.component';

/** @namespace Bodypwa/Component/PrivacyPolicyButton/Container/mapStateToProps */
export const mapStateToProps = () => ({
});

/** @namespace Bodypwa/Component/PrivacyPolicyButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup(PRIVACY_POLICY_POPUP_ID, payload))
});

/** @namespace Bodypwa/Component/PrivacyPolicyButton/Container */
export class PrivacyPolicyButtonContainer extends PureComponent {
    static propTypes = {
        showPopup: PropTypes.func.isRequired,
        children: ChildrenType.isRequired
    };

    containerFunctions = {
        onButtonClick: this.onButtonClick.bind(this)
    };

    onButtonClick(e) {
        e.preventDefault();
        e.stopPropagation();

        // eslint-disable-next-line react/prop-types
        const { showPopup, privacyPolicy } = this.props;

        /* super-ugly workaround :|
        Issue: without timeout, the popup is shown immediately, then the click is somehow captured by ClickOutside
        wrappers, which are meant to close the current popup when the user clicks outside, and the popup is
        immediately hidden.
        */
        setTimeout(() => {
            showPopup({ privacyPolicy });
        // eslint-disable-next-line no-magic-numbers
        }, 42);
    }

    render() {
        const { children } = this.props;

        return (
            <PrivacyPolicyButtonComponent
              { ...this.containerFunctions }
            >
                { children }
            </PrivacyPolicyButtonComponent>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicyButtonContainer);
