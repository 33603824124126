import { connect } from 'react-redux';

import { setScrollToElement } from 'Store/Ui/Ui.action';

import OverlayComponent from './Overlay.component';

/** @namespace Bodypwa/Component/Overlay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    activeOverlay: state.OverlayReducer.activeOverlay,
    areOtherOverlaysOpen: state.OverlayReducer.areOtherOverlaysOpen,
    isMobile: state.ConfigReducer.device.isMobile,
    headerHeight: state.UiReducer.headerHeight,
    scrollToElement: state.UiReducer.scrollToElement
});

/** @namespace Bodypwa/Component/Overlay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setScrollToElement: (scrollToElement) => dispatch(setScrollToElement(scrollToElement))
});

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/always-both-mappings
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(OverlayComponent);
