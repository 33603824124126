import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DataContainer from 'Util/Request/DataContainer';

import Widget from './Widget.component';

/** @namespace Bodypwa/Component/Widget/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    layoutUpdates: state.ConfigReducer.layoutUpdates,
    baseUrl: state.ConfigReducer.base_url
});
/** @namespace Bodypwa/Component/Widget/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({
});
/** @namespace Bodypwa/Component/Widget/Container */
export class WidgetContainer extends DataContainer {
    static propTypes = {
        id: PropTypes.string.isRequired
    };

    containerFunctions = {};

    containerProps() {
        const { layoutUpdates, id, baseUrl } = this.props;
        const widget = layoutUpdates.find((widget) => widget.id === id) || {};
        const { params } = widget;
        return {
            widget: {
                ...widget,
                params: JSON.parse(params || '{}')
            },
            baseUrl,
            isShouldDisplay: Object.keys(widget).length
        };
    }

    render() {
        const { layoutUpdates, id } = this.props;
        const widget = layoutUpdates.find((widget) => widget.id === id) || {};
        const { instance_id } = widget || {};
        if (!instance_id) {
            const widget = layoutUpdates.find((widget) => widget.instance_id === id) || {};
            const { min_height } = widget;
            if (min_height) {
                return (
                    <div style={ { minHeight: `${min_height}px` } } />
                );
            }

            return null;
        }

        return (
            <Widget
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetContainer);
