/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable max-lines */

import { withLayoutUpdates } from 'hoc/withLayoutUpdates';
import PropTypes from 'prop-types';
import {
    createRef,
    Suspense
} from 'react';

import Button from 'Component/Button';
import { SIZE_NONE, TYPE_TEXT, WIDTH_HUG } from 'Component/Button/Button.config';
import CartIcon from 'Component/CartIcon';
import { CATEGORY_FILTER_OVERLAY_ID } from 'Component/CategoryFilterOverlay/CategoryFilterOverlay.config';
import ChevronIcon from 'Component/ChevronIcon';
import { LEFT } from 'Component/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'Component/ClickOutside';
import CloseIcon from 'Component/CloseIcon';
import CmsBlock from 'Component/CmsBlock';
import CrossIcon from 'Component/CrossIcon';
// import FIELD_TYPE from 'Component/Field/Field.config';
import HeartIcon from 'Component/HeartIcon';
import { BLACK_KEY, M_KEY } from 'Component/Icon/Icon.config';
import Link from 'Component/Link';
import Logo from 'Component/Logo';
import MenuSearchIcon from 'Component/MenuSearchIcon';
import { CUSTOMER_ACCOUNT_OVERLAY_KEY } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import NavigationAbstract from 'Component/NavigationAbstract/NavigationAbstract.component';
import { DEFAULT_STATE_NAME } from 'Component/NavigationAbstract/NavigationAbstract.config';
import PopupSuspense from 'Component/PopupSuspense';
import SearchField from 'Component/SearchField';
import SearchIcon from 'Component/SearchIcon';
import { HEADER_STORE_SELECTOR } from 'Component/StoreSelector/StoreSelector.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import UserIcon from 'Component/UserIcon';
import { BILLING_STEP, SUCCESS_URL } from 'Route/Checkout/Checkout.config';
import { DeviceType } from 'Type/Device.type';
import { TotalsType } from 'Type/MiniCart.type';
import { isSignedIn } from 'Util/Auth';
import CSS from 'Util/CSS';
import history from 'Util/History';
import { lazyComponentLoader } from 'Util/lazyComponentLoader';
import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';

import { TYPE_LINK } from '../Button/Button.config';
import {
    CART,
    CART_EDITING,
    CART_OVERLAY,
    CATEGORY,
    CHECKOUT,
    CHECKOUT_ACCOUNT,
    CHECKOUT_SUCCESS,
    CMS_PAGE,
    CONTACT_US,
    CUSTOMER_ACCOUNT,
    CUSTOMER_ACCOUNT_PAGE,
    CUSTOMER_SUB_ACCOUNT,
    CUSTOMER_WISHLIST,
    FILTER,
    MENU,
    MENU_SUBCATEGORY,
    NO_MATCH,
    PDP,
    POPUP,
    SEARCH
} from './Header.config';

import './Header.style';

export const Menu = lazyComponentLoader(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "menu" */
        'Component/Menu'
    ), 2
);

export const MyAccountOverlay = lazyComponentLoader(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "header-account" */
        'Component/MyAccountOverlay'
    ), 2
);

export const StoreSelector = lazyComponentLoader(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "header-additional" */
        'Component/StoreSelector'
    ), 2
);

export const CartOverlay = lazyComponentLoader(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "header-additional" */
        'Component/CartOverlay'
    ), 2
);

export const HamburgerMenu = lazyComponentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "navigation-extension" */
    'Component/HamburgerMenu'
), 2);

/** @namespace Bodypwa/Component/Header/Component */
export class HeaderComponent extends NavigationAbstract {
    static propTypes = {
        navigationState: PropTypes.shape({
            name: PropTypes.string,
            onBackClick: PropTypes.func,
            title: PropTypes.string
        }).isRequired,
        cartTotals: TotalsType.isRequired,
        onBackButtonClick: PropTypes.func.isRequired,
        onCloseButtonClick: PropTypes.func.isRequired,
        onSearchBarFocus: PropTypes.func.isRequired,
        onClearSearchButtonClick: PropTypes.func.isRequired,
        onMyAccountButtonClick: PropTypes.func.isRequired,
        onSearchBarChange: PropTypes.func.isRequired,
        isWishlistLoading: PropTypes.bool.isRequired,
        onEditButtonClick: PropTypes.func.isRequired,
        onMinicartButtonClick: PropTypes.func.isRequired,
        onOkButtonClick: PropTypes.func.isRequired,
        onCancelButtonClick: PropTypes.func.isRequired,
        onSearchOutsideClick: PropTypes.func.isRequired,
        onMyAccountOutsideClick: PropTypes.func.isRequired,
        onMinicartOutsideClick: PropTypes.func.isRequired,
        isClearEnabled: PropTypes.bool.isRequired,
        searchCriteria: PropTypes.string.isRequired,
        header_logo_src: PropTypes.string,
        logo_alt: PropTypes.string,
        logo_height: PropTypes.number,
        logo_width: PropTypes.number,
        isLoading: PropTypes.bool,
        showMyAccountLogin: PropTypes.bool,
        isCheckout: PropTypes.bool.isRequired,
        onSignIn: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        renderTopMenu: PropTypes.func.isRequired,
        device: DeviceType.isRequired
    };

    static defaultProps = {
        logo_alt: 'ScandiPWA logo',
        logo_height: 25,
        logo_width: 200,
        showMyAccountLogin: false,
        header_logo_src: '',
        isLoading: true
    };

    logoRef = createRef();

    stateMap = {
        [DEFAULT_STATE_NAME]: {
            title: true,
            logo: true,
            menu: true, // close was undefined => true
            searchIcon: true,
            search: true,
            account: true,
            minicart: true
            // logo is also enforced true again, but it was already true above
        },
        [NO_MATCH]: {
            title: true,
            menu: true, // close was undefined => true
            searchIcon: true,
            search: true,
            account: true,
            minicart: true,
            logo: true
        },
        [POPUP]: {
            title: true,
            close: true,
            menu: false, // close === true => false
            searchIcon: true,
            search: true,
            account: true,
            minicart: true,
            logo: true
        },
        [PDP]: {
            back: true,
            title: true,
            menu: true,
            searchIcon: true,
            search: true,
            account: true,
            minicart: true,
            logo: true
        },
        [CATEGORY]: {
            back: true,
            title: true,
            menu: true,
            searchIcon: true,
            search: true,
            account: true,
            minicart: true,
            logo: true
        },
        [CUSTOMER_ACCOUNT]: {
            title: true,
            menu: true,
            searchIcon: true,
            search: true,
            account: true,
            minicart: true,
            logo: true
        },
        [CUSTOMER_SUB_ACCOUNT]: {
            title: true,
            back: true,
            menu: true,
            searchIcon: true,
            search: true,
            account: true,
            minicart: true,
            logo: true
        },
        [CUSTOMER_ACCOUNT_PAGE]: {
            title: true,
            menu: true,
            searchIcon: true,
            search: true,
            account: true,
            minicart: true,
            logo: true
        },
        [CUSTOMER_WISHLIST]: {
            title: true,
            ok: true,
            menu: true,
            searchIcon: true,
            search: true,
            account: true,
            minicart: true,
            logo: true
        },
        [MENU]: {
            // originally { search: true }
            search: true,
            menu: true, // close was undefined => true
            searchIcon: true,
            account: true,
            minicart: true,
            logo: true
        },
        [MENU_SUBCATEGORY]: {
            back: true,
            title: true,
            search: true,
            menu: true, // close was undefined => true
            searchIcon: true,
            account: true,
            minicart: true,
            logo: true
        },
        [SEARCH]: {
            search: true,
            menu: true, // close was undefined => true
            searchIcon: true,
            account: true,
            minicart: true,
            logo: true
        },
        [CART]: {
            title: true,
            menu: true, // close was undefined => true
            searchIcon: true,
            search: true,
            account: true,
            minicart: true,
            logo: true
        },
        [CART_OVERLAY]: {
            title: true,
            menu: true,
            searchIcon: true,
            search: true,
            account: true,
            minicart: true,
            logo: true
        },
        [CART_EDITING]: {
            ok: true,
            title: true,
            cancel: true,
            menu: true, // close was undefined => true
            searchIcon: true,
            search: true,
            account: true,
            minicart: true,
            logo: true
        },
        [FILTER]: {
            close: true,
            title: true,
            menu: false, // close === true => false
            searchIcon: true,
            search: true,
            account: true,
            minicart: true,
            logo: true
        },
        [CHECKOUT]: {
            back: true,
            title: true,
            account: true,
            menu: true, // close was undefined => true
            searchIcon: true,
            search: true,
            minicart: true,
            logo: true
        },
        [CHECKOUT_SUCCESS]: {
            title: true,
            account: true,
            menu: true,
            searchIcon: true,
            search: true,
            minicart: true,
            logo: true
        },
        [CHECKOUT_ACCOUNT]: {
            title: true,
            close: true,
            menu: false, // close === true => false
            searchIcon: true,
            search: true,
            account: true,
            minicart: true,
            logo: true
        },
        [CMS_PAGE]: {
            back: true,
            title: true,
            menu: true, // close was undefined => true
            searchIcon: true,
            search: true,
            account: true,
            minicart: true,
            logo: true
        },
        [CONTACT_US]: {
            title: true,
            back: true,
            menu: true, // close was undefined => true
            searchIcon: true,
            search: true,
            account: true,
            minicart: true,
            logo: true
        }
    };

    renderMap = {
        menu: this.renderOpenMenuButton.bind(this),
        searchIcon: this.renderSearchIconMobile.bind(this),
        cancel: this.renderCancelButton.bind(this),
        back: this.renderBackButton.bind(this),
        close: this.renderCloseButton.bind(this),
        title: this.renderTitle.bind(this),
        ok: this.renderOkButton.bind(this),
        logo: this.renderLogoMobile.bind(this),
        headerlinks: this.renderHeaderLinks.bind(this),
        account: this.renderAccountMobile.bind(this),
        search: this.renderSearchFieldMobile.bind(this),
        minicart: this.renderMiniCart.bind(this),
        closeSearch: this.renderCloseSearchButton.bind(this)
    };

    renderMapDesktop = {
        searchIcon: this.renderSearchIcon.bind(this),
        account: this.renderAccount.bind(this),
        wishlist: this.renderWishList.bind(this),
        minicart: this.renderMinicart.bind(this)
    };

    renderOpenMenuButton() {
        const {
            openSideMenu,
            closeSideMenu,
            isSearchBarActive,
            device: { isMobile }
        } = this.props;

        if (isSearchBarActive || !isMobile) {
            return null;
        }

        return (
            <ClickOutside
              onClick={ closeSideMenu }
              key="sideMenu"
            >
                <div block="Header" elem="HamburgerMenuWrapper">
                    <Button
                      onClick={ openSideMenu }
                      aria-label={ __('Open Menu') }
                      width={ WIDTH_HUG }
                      size={ SIZE_NONE }
                      type={ TYPE_TEXT }
                    >
                        <MenuSearchIcon />
                    </Button>
                    <Suspense fallback={ null }>
                        <HamburgerMenu />
                    </Suspense>
                </div>
            </ClickOutside>
        );
    }

    // PureComponent suits perfectly for current component, as changes in almost all props (7+) need to trigger rerender.
    // Yet shouldComponentUpdate() is overridden in another component also extending NavigationAbstract
    // (i.e. NavigationTabs) to minimize rerenders. => We can't extend PureComponent from Header.
    // This is why shallow comparison behavior for all props  (like in PureComponent) is used here.
    shouldComponentUpdate(nextProps) {
        return Object.keys(nextProps).some((key) => nextProps[key] !== this.props[key]);
    }

    renderBackButton(isVisible = false) {
        const { onBackButtonClick, device: { isMobile } } = this.props;
        const classHideButton = !isVisible ? '' : '!hidden';
        if (!isMobile) {
            return null;
        }

        return (
            <button
              key="back"
              block="Header"
              elem="Button"
              className={ `${classHideButton}` }
              mods={ { type: 'back', isVisible } }
              onClick={ onBackButtonClick }
              aria-label="Go back"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
            >
                <ChevronIcon direction={ LEFT } />
            </button>
        );
    }

    renderCloseButton(isVisible = false) {
        const { onCloseButtonClick, device: { isMobile } } = this.props;

        if (!isMobile) {
            return null;
        }

        return (
            <button
              key="close"
              block="Header"
              elem="Button"
              mods={ { type: 'close', isVisible } }
              onClick={ onCloseButtonClick }
              aria-label="Close"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
            >
                <CloseIcon />
            </button>
        );
    }

    headerRef = createRef();

    navigationRef = createRef();

    headerTopRef = createRef();

    componentDidUpdate() {
        const {
            setHeaderHeight, setHeaderPlaceholderHeight, setHeaderElement, setHeaderTopRef
        } = this.props;
        const height = this.logoRef?.current?.offsetHeight;

        setHeaderHeight(height || 0);
        setHeaderPlaceholderHeight(height + 32);

        if (this.logoRef?.current) {
            setHeaderElement(this.logoRef.current);
        }

        if (this.headerTopRef?.current) {
            setHeaderTopRef(this.headerTopRef.current);
        }
    }

    renderTopMenu() {
        const { isCheckout } = this.props;
        if (isCheckout) {
            return null;
        }

        return (
            <>
                <div
                  block="Topbanner"
                  elem="Wrapper"
                >
                    <div block="Topbanner" elem="Columns">
                        { this.renderContent('social_reviews_top') }
                    </div>
                </div>
                <div
                  block="Topbanner"
                  elem="Wrapper"
                  mods={ { labels: true } }
                >
                    <div block="Topbanner" elem="Columns">
                        { this.renderContent('pwa-header-labels-desktop', 'pwa-header-labels-mobile') }
                    </div>
                </div>
            </>
        );
    }

    renderWishList() {
        const { onWishlistClick, hideWishlistButton } = this.props;
        const link = isSignedIn() ? '/my-account/my-wishlist' : '/account/login';

        if (hideWishlistButton) {
            return null;
        }

        return (
            <Link
              tabIndex="0"
              to={ link }
              aria-label={ __('Go to wishlist') }
              block="Header"
              onClick={ onWishlistClick }
              elem="WishlistLinkWrapper"
              key="linkWishlist"
            >
                <HeartIcon />
            </Link>
        );
    }

    renderMinicartOverlay() {
        const { shouldRenderCartOverlay } = this.props;

        if (!shouldRenderCartOverlay) {
            return null;
        }

        return (
            <Suspense fallback={ null }>
                <CartOverlay />
            </Suspense>
        );
    }

    renderMinicart(isVisible = false) {
        const {
            onMinicartOutsideClick,
            isCheckout,
            device: { isMobile }
        } = this.props;

        if (isMobile || (isCheckout && this.isSuccsessPage())) {
            return null;
        }

        return (
            <ClickOutside
              onClick={ onMinicartOutsideClick }
              key="minicart"
            >
                <div
                  block="Header"
                  elem="Button"
                  mods={ { isVisible, type: 'minicart' } }
                >
                    { this.renderMinicartButton() }
                    { this.renderMinicartOverlay() }
                </div>
            </ClickOutside>
        );
    }

    renderOkButton(isVisible = false) {
        const { onOkButtonClick } = this.props;

        return (
            <button
              key="ok"
              block="Header"
              elem="Button"
              mods={ { type: 'ok', isVisible } }
              onClick={ onOkButtonClick }
              aria-label="Save changes"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
            >
                { __('OK') }
            </button>
        );
    }

    renderWelcomeMessage() {
        const { firstname } = this.props;

        if (!isSignedIn() || !firstname) {
            return null;
        }

        return (
            <div
              block="Header"
              elem="Welcome"
              mods={ { type: 'Welcome' } }
            >
                { __('Welcome, %s!', firstname) }
            </div>
        );
    }

    renderCancelButton(isVisible = false) {
        const { onCancelButtonClick } = this.props;

        return (
            <button
              key="cancel"
              block="Header"
              elem="Button"
              mods={ { type: 'cancel', isVisible } }
              onClick={ onCancelButtonClick }
              aria-label="Cancel changes"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
            >
                { __('Cancel') }
            </button>
        );
    }

    renderSearchField(isVisible = false) {
        const {
            searchCriteria,
            onSearchOutsideClick,
            onSearchBarFocus,
            onSearchBarChange,
            onClearSearchButtonClick,
            navigationState: { name },
            isCheckout,
            hideActiveOverlay
        } = this.props;

        if (isCheckout && this.isSuccsessPage()) {
            return null;
        }

        return (
            <SearchField
              key="search"
              searchCriteria={ searchCriteria }
              onSearchOutsideClick={ onSearchOutsideClick }
              onSearchBarFocus={ onSearchBarFocus }
              onSearchBarChange={ onSearchBarChange }
              onClearSearchButtonClick={ onClearSearchButtonClick }
              isVisible={ isVisible }
              isActive={ name === SEARCH }
              hideActiveOverlay={ hideActiveOverlay }
            />
        );
    }

    renderDesktopActions() {
        const { device: { isMobile }, isSearchBarActive } = this.props;
        if (isMobile || isSearchBarActive) {
            return null;
        }

        return Object.entries(this.renderMapDesktop).map(
            // eslint-disable-next-line no-unused-vars
            ([key, renderFunction]) => renderFunction()
        );
    }

    renderHeaderLinks() {
        const { device: { isMobile }, topPageLinks, isSearchBarActive } = this.props;

        if (isMobile || !topPageLinks || isSearchBarActive) {
            return null;
        }

        return (
            <div
              block="Header"
              elem="Links"
            >
                { this.renderColumns(topPageLinks) }
            </div>
        );
    }

    renderColumns = (columns) => {
        const { items } = columns;
        const { device } = this.props;
        if (device.isMobile) {
            return (
                <div
                  mix={ { block: 'Header', elem: 'Column' } }
                  className="Header-ColumnContent"
                  // eslint-disable-next-line react/jsx-boolean-value
                  isArrow={ true }
                  isContentExpanded={ false }
                >
                    <div
                      block="Header"
                      elem="ColumnContent"

                    >
                        { items ? items.map(this.renderColumnItem) : columns.map(this.renderColumnItem) }
                    </div>

                </div>
            );
        }

        return (
            <div block="Header" elem="Column">
                <div
                //   block="Header"
                //   elem="ColumnContent"
                  className="flex items-center"
                >
                    { items ? items.map(this.renderColumnItem) : columns.map(this.renderColumnItem) }
                    <div
                      block="Header"
                      elem="Switcher"
                    >
                        <Suspense fallback={ null }>
                            <StoreSelector isSimple storeSelectorId={ HEADER_STORE_SELECTOR } isRedirectPopUp />
                        </Suspense>
                    </div>
                </div>
            </div>
        );
    };

    renderColumnItem = (item, i) => {
        const { url, label } = item;
        return (
            <Button
              goTo={ url }
              key={ i }
              isLink
              size={ SIZE_NONE }
              width={ WIDTH_HUG }
              type={ TYPE_LINK }
              className="!mr-4 !text-p12"
            >
                { label }
            </Button>
        );
    };

    renderTitle() {
        return null;
    }

    renderLogoImage() {
        const {
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width
        } = this.props;

        // if no src defined from the backend, pass null in order to display placeholder
        // and prevent unnecessary load of corrupted resource
        const logoSrc = header_logo_src ? media(header_logo_src, LOGO_MEDIA) : null;

        CSS.setVariable(this.logoRef, 'header-logo-height', `${logo_height}px`);
        CSS.setVariable(this.logoRef, 'header-logo-width', `${logo_width}px`);

        return (
            <Logo
              src={ logoSrc }
              alt={ logo_alt }
              title={ logo_alt }
              className="md:h-10 h-8"
            />
        );
    }

    renderContent(identifierDesktop, identifierMobile) {
        const isMobile = window.matchMedia('(max-width: 1023px)').matches;
        if (!identifierDesktop) {
            return null;
        }
        if (isMobile) {
            return (
                <CmsBlock identifier={ identifierMobile || identifierDesktop } />
            );
        }

        return (
            <CmsBlock identifier={ identifierDesktop } />
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    isSuccsessPage() {
        const {
            location: {
                pathname
            }
        } = history;

        return !pathname.includes(SUCCESS_URL);
    }

    renderMenu() {
        const {
            isCheckout, device: { isMobile }, headerHeight, headerMenu, isSearchBarActive
        } = this.props;

        if (isSearchBarActive) {
            return null;
        }

        if (isMobile || (isCheckout && this.isSuccsessPage()) || !headerMenu) {
            return null;
        }

        return <Menu headerHeight={ headerHeight } />;
    }

    // TODO refator this
    renderAccountMobile() {
        const {
            onMobileMyAccountButtonClick,
            isSearchBarActive,
            device: { isMobile },
            isCheckout
        } = this.props;

        if (isMobile) {
            if (isSearchBarActive) {
                return null;
            }

            return (
                <Button
                  type={ TYPE_TEXT }
                  size={ SIZE_NONE }
                  onClick={ onMobileMyAccountButtonClick }
                  aria-label="Open my account"
                >
                    <UserIcon size={ M_KEY } color={ BLACK_KEY } />
                </Button>
            );
        }

        if (isCheckout && isSignedIn()) {
            return null;
        }

        return null;
    }

    renderAccount(isVisible = false) {
        const {
            isCheckout,
            device: { isMobile }
        } = this.props;

        // on mobile hide button if not in checkout
        if (isMobile && !isCheckout) {
            return null;
        }

        if (isCheckout && isSignedIn() && this.isSuccsessPage()) {
            return null;
        }

        return (
            <>
                { this.renderAccountButton(isVisible) }
                { this.renderAccountOverlay() }
            </>
        );
    }

    renderMinicartItemsQty() {
        const { cartTotals: { items_qty } } = this.props;

        if (!items_qty) {
            return null;
        }

        return (
            <span
              aria-label="Items in cart"
              block="Header"
              elem="MinicartItemCount"
              className="!bg-transparent pointer-events-none !top-1 bottom-0 right-0 left-0 m-auto md:top-[0.375rem]"
            >
                { items_qty }
            </span>
        );
    }

    renderMinicartOverlayFallback() {
        return (
            <PopupSuspense
              actualOverlayKey={ CART_OVERLAY }
            />
        );
    }

    renderAccountButton() {
        const {
            onMyAccountButtonClick,
            device
        } = this.props;

        if (device.isMobile) {
            return null;
        }

        return (
            <Button
              tabIndex="0"
              onClick={ onMyAccountButtonClick }
              type={ TYPE_TEXT }
              width={ WIDTH_HUG }
              size={ SIZE_NONE }
            >
                <UserIcon size={ M_KEY } color={ BLACK_KEY } />
            </Button>
        );
    }

    renderPaymentBackButton() {
        const {
            activeStep
        } = this.props;

        if (activeStep === BILLING_STEP) {
            return (
                <Button
                  gTto="/checkout/shipping"
                  type={ TYPE_TEXT }
                  width={ WIDTH_HUG }
                  isLink
                  size={ SIZE_NONE }

                >
                   <ChevronIcon direction={ LEFT } size={ M_KEY } color={ BLACK_KEY } />
                </Button>
            );
        }

        return null;
    }

    renderHeader() {
        const {
            navigationState: { name, isHiddenOnMobile = false },
            isCheckout,
            setIsCheckout,
            activeOverlay,
            headerPlaceholderHeight,
            headerHeight,
            renderTopMenu
        } = this.props;

        document.documentElement.style.setProperty('--header-height', `${headerPlaceholderHeight}px`);
        setIsCheckout();
        if (isCheckout && this.isSuccsessPage()) {
            return (
                <section
                  block="Header"
                  className={ isCheckout ? 'Header-Checkout' : '' }
                  elem="Wrapper"
                  ref={ this.headerRef }
                >
                    { renderTopMenu(this.renderTopMenu.bind(this)) }
                    <header
                      block="CheckoutHeader"
                      mods={ { name, isHiddenOnMobile, isCheckout } }
                      mix={ { block: 'FixedElement', elem: 'Top' } }
                      ref={ this.logoRef }
                    >
                        <nav
                          className="flex items-center content-center items-center md:justify-normal md:px-8
                            md:my-4 !max-w-1400 w-full"
                        >
                            { this.renderPaymentBackButton() }
                            { this.renderLogo(true) }
                        </nav>
                    </header>
                </section>
            );
        }

        return (
            <section
              block="Header"
              elem="Wrapper"
              style={ {
                  '--header-dinamic-height': `${headerPlaceholderHeight}px`,
                  '--header-height': `${headerHeight}px`,
                  // eslint-disable-next-line no-magic-numbers
                  '--offset-left': `${ (this.navigationRef?.current?.getBoundingClientRect().left + 32) || 0}px`,
                  minHeight: headerPlaceholderHeight
              } }
              ref={ this.headerRef }
            >
                { renderTopMenu(this.renderTopMenu.bind(this)) }
                <div ref={ this.headerTopRef } />
                <header
                  block="Header"
                  className="!bg-white-normal min-h-fit flex flex-col pt-0
                    w-full z-[100] border-b-2 border-grey-200 border-solid md:max-h-[6.688rem]
                    md:m-auto"
                  mods={ {
                      name,
                      isHiddenOnMobile,
                      isCheckout,
                      activeCatOverlay: activeOverlay === CATEGORY_FILTER_OVERLAY_ID,
                      scrollTop: !+document.documentElement.dataset.scrollPosition
                  } }
                  mix={ { block: 'FixedElement', elem: 'Top' } }
                  ref={ this.logoRef }
                >
                    <nav
                      block="Header"
                      elem="Nav"
                      className="flex items-center content-center items-center md:justify-normal md:px-8
                       md:my-4 !max-w-1400 w-full mx-auto"
                      ref={ this.navigationRef }
                    >
                        { this.renderNavigationState() }
                    </nav>
                    <div
                      block="Header"
                      elem="Bottom"
                      className="flex flex-row-reverse px-8 !max-w-1400 w-full pb-0.5 justify-between mx-auto"
                    >
                        <div
                          block="Header"
                          elem="Buttons"
                          className="md:flex md:gap-4 md:mr-[calc(-1 * var(--space-2))] md:items-start"
                        >
                            { this.renderDesktopActions() }
                        </div>
                        { this.renderMenu() }
                    </div>
                </header>
            </section>
        );
    }

    renderAccountOverlay() {
        const {
            isCheckout,
            showMyAccountLogin,
            onSignIn
        } = this.props;

        // This is here to prevent the popup-suspense from rendering
        if (!showMyAccountLogin) {
            return null;
        }

        return (
            <Suspense fallback={ this.renderAccountOverlayFallback() }>
                <MyAccountOverlay
                  onSignIn={ onSignIn }
                  isCheckout={ isCheckout }
                />
            </Suspense>
        );
    }

    renderLogo(isVisible = false) {
        const { isLoading } = this.props;
        const content = isLoading ? <TextPlaceholder height="logo" length="logo" />
            : this.renderLogoImage();

        return (
            <Link
              to="/"
              aria-label="Go to homepage by clicking on ScandiPWA logo"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
              block="Header"
              elem="LogoWrapper"
              mods={ { isVisible } }
              key="logo"
            >
                { content }
            </Link>
        );
    }

    renderAccountOverlayFallback() {
        return (
            <PopupSuspense
              actualOverlayKey={ CUSTOMER_ACCOUNT_OVERLAY_KEY }
            />
        );
    }

    renderMinicartButton() {
        const {
            onMinicartButtonClick
        } = this.props;

        return (
            <Button
              tabIndex="0"
              onClick={ onMinicartButtonClick }
              aria-label={ __('Cart') }
              type={ TYPE_TEXT }
              width={ WIDTH_HUG }
              size={ SIZE_NONE }
            >

                <CartIcon size={ M_KEY } color={ BLACK_KEY } isTransparent={ false } isChecked={ false } />
                { this.renderMinicartItemsQty() }
            </Button>
        );
    }

    renderSearchIconMobile() {
        const {
            isSearchBarActive,
            onSearchButtonClick,
            device: { isMobile }
        } = this.props;

        if (isSearchBarActive || !isMobile) {
            return null;
        }

        return (
            <Button
              type={ TYPE_TEXT }
              size={ SIZE_NONE }
              onClick={ onSearchButtonClick }
              aria-label={ __('Start Search') }
            >
                 <SearchIcon size={ M_KEY } color={ BLACK_KEY } />
            </Button>
        );
    }

    renderLogoMobile(isVisible) {
        const { isSearchBarActive, device: { isMobile } } = this.props;

        if (isSearchBarActive && isMobile) {
            return null;
        }

        return this.renderLogo(isVisible);
    }

    renderSearchFieldMobile() {
        const { isSearchBarActive } = this.props;

        if (!isSearchBarActive) {
            return null;
        }

        return this.renderSearchField(true);
    }

    renderMiniCart(isVisible) {
        const {
            isSearchBarActive,
            isCheckout,
            device: { isMobile }
        } = this.props;
        const {
            location: {
                pathname
            }
        } = history;

        if (isCheckout && !pathname.includes(SUCCESS_URL)) {
            return null;
        }

        if (!isMobile) {
            return null;
        }

        if (isSearchBarActive) {
            return null;
        }

        return this.renderMinicartButton(isVisible);
    }

    renderCloseSearchButton() {
        const {
            isSearchBarActive,
            onSearchBarDeactivate
        } = this.props;

        if (!isSearchBarActive) {
            return null;
        }

        return (
            <Button
              className="CloseSearchBtn font-normal"
              key="closeSearch"
              onClick={ onSearchBarDeactivate }
              aria-label={ __('Cancel Search') }
              type={ TYPE_TEXT }
              width={ WIDTH_HUG }
              isIconsmall
            >
                <CrossIcon size="xs" color="black" />
                <div className="CancelText">{ __('Cancel') }</div>
            </Button>
        );
    }

    renderSearchIcon() {
        const {
            isSearchBarActive,
            onSearchButtonClick
        } = this.props;

        if (isSearchBarActive) {
            return null;
        }

        return (
            <Button
              key="searchBtn"
              onClick={ onSearchButtonClick }
              aria-label={ __('Start Search') }
              type={ TYPE_TEXT }
              width={ WIDTH_HUG }
              size={ SIZE_NONE }

            >
                <SearchIcon size={ M_KEY } color="black" />
            </Button>
        );
    }

    render() {
        return this.renderHeader();
    }
}

export * from 'Component/Header/Header.component';
export default withLayoutUpdates(HeaderComponent);
