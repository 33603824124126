// This handles the width of the button
export const WIDTH_HUG = 'hug';
export const WIDTH_FIXED = 'fixed';
export const WIDTH_FULL = 'full';
// This handles the size/height of the button
export const SIZE_LARGE = 'large';
export const SIZE_SMALL = 'small';
export const SIZE_NONE = 'none';
// This sets the type of the button
export const TYPE_PRIMARY = 'primary';
export const TYPE_SECONDARY = 'secondary';
export const TYPE_TERTIARY = 'tertiary';
export const TYPE_TEXT = 'text';
export const TYPE_LINK = 'link';
// This sets the icon position
export const POSITION_LEADING = 'leading';
export const POSITION_TRAILING = 'trailing';
// This sets the text and icon on the left side
export const TEXT_LEFT = 'left';
export const TEXT_CENTER = 'center';

export const BUTTON_SKELETON = [
    {
        slider: true,
        columns: 10,
        gap: 10,
        height: 56,
        width: '100%'
    }
];
