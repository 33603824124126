// Prop value config
export const XXXS_VALUE = '5';
export const XXS_VALUE = '10';
export const XS_VALUE = '16';
export const S_VALUE = '20';
export const M_VALUE = '24';
export const L_VALUE = '32';
export const XL_VALUE = '48';
// Prop key config
export const XXXS_KEY = 'xxxs';
export const XXS_KEY = 'xs';
export const S_KEY = 's';
export const XS_KEY = 'xs';
export const M_KEY = 'm';
export const L_KEY = 'l';
export const XL_KEY = 'xl';
// Prop value colors
export const COLOR_BLACK = '#070707';
export const COLOR_WHITE = '#fff';
export const COLOR_GREY = '#A3A3A3';
export const COLOR_GREEN = '#107F51';
export const COLOR_BLUE = '#4116F8';
export const COLOR_GREY300 = '#C1C1C1';
export const COLOR_GREY500 = '#707070';
export const COLOR_GREY600 = '#474747';
export const COLOR_RED500 = '#D50B26';
// Prop key colors
export const WHITE_KEY = 'white';
export const BLACK_KEY = 'black';
export const GREEN_KEY = 'green';
export const BLUE_KEY = 'blue';
export const GREY_KEY = 'grey';
export const GREY300_KEY = 'grey300';
export const GREY500_KEY = 'grey500';
export const GREY600_KEY = 'grey600';
export const RED400_KEY = 'red400';
