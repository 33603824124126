/* eslint-disable max-lines */
// import { useProductLayoutUpdate } from 'hooks/useLayoutUpdate';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { updateConfigDevice } from 'Store/Config/Config.action';
import { updateMeta } from 'Store/Meta/Meta.action';
import { MetaTitleType } from 'Type/Common.type';
import { toggleScroll } from 'Util/Browser';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import componentLoader from 'Util/componentLoader';
import {
    isMobile,
    isMobileClientHints,
    isUsingClientHints
} from 'Util/Mobile';

// import { fetchQuery } from 'Util/Request';
import Router from './Router.component';

export const PaymentMethodsDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/PaymentMethods/PaymentMethods.dispatcher'
), 2);

export const CartDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
), 2);
export const ConfigDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Config/Config.dispatcher'
), 2);
export const WishlistDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
), 2);
export const MyAccountDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
), 2);
export const GoogleTagManagerDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '@scandipwa/gtm-new/src/store/GoogleTagManager/GoogleTagManager.dispatcher'
), 2);
export const StoresDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Stores/Stores.dispatcher'
);
export const LayoutUpdatesDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/LayoutUpdates/LayoutUpdates.dispatcher'
), 2);
export const CmsDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cms/Cms.dispatcher'
), 2);

/** @namespace Bodypwa/Component/Router/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isLoading: state.ConfigReducer.isLoading,
    default_description: state.ConfigReducer.default_description,
    default_keywords: state.ConfigReducer.default_keywords,
    default_title: state.ConfigReducer.default_title,
    title_prefix: state.ConfigReducer.title_prefix,
    title_suffix: state.ConfigReducer.title_suffix,
    meta_title: state.MetaReducer.title,
    device: state.ConfigReducer.device,
    isOffline: state.OfflineReducer.isOffline,
    isBigOffline: state.OfflineReducer.isBig,
    status_code: state.MetaReducer.status_code,
    paymentMethods: state.PaymentMethodsReducer,
    cartItems: state.CartReducer?.cartTotals?.items,
    isNoHreflangs: state.MetaReducer.isNoHreflangs,
    layoutUpdates: state.ConfigReducer.layoutUpdates,
    pageType: state.UrlRewritesReducer.pageType,
    product: state.ProductReducer?.product || {},
    isCookieScriptEnabled: state.ConfigReducer?.cookie_script?.enabled
});

/** @namespace Bodypwa/Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    updateConfigDevice: (device) => dispatch(updateConfigDevice(device)),
    init: () => {
        ConfigDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch)
        );
        MyAccountDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleCustomerDataOnInit(dispatch)
        );
        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
        );
        GoogleTagManagerDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch)
        );
        StoresDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch)
        );
        CmsDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch)
        );
    },
    getPaymentMethods: () => {
        PaymentMethodsDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch, null)
        );
    },
     getLayoutUpdates: (options) => {
        LayoutUpdatesDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch, options)
        );
    }
});
/** @namespace Bodypwa/Component/Router/Container */
export class RouterContainer extends PureComponent {
    static propTypes = {
        init: PropTypes.func.isRequired,
        updateMeta: PropTypes.func.isRequired,
        updateConfigDevice: PropTypes.func.isRequired,
        base_link_url: PropTypes.string,
        default_description: PropTypes.string,
        default_keywords: PropTypes.string,
        default_title: PropTypes.string,
        title_prefix: PropTypes.string,
        title_suffix: PropTypes.string,
        isLoading: PropTypes.bool,
        isBigOffline: PropTypes.bool,
        meta_title: MetaTitleType,
        status_code: PropTypes.string,
        isNoHreflangs: PropTypes.bool.isRequired,
        cartItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        paymentMethods: PropTypes.shape({
            methods: PropTypes.arrayOf(PropTypes.shape({}))
        }).isRequired,
        getPaymentMethods: PropTypes.func.isRequired,
        layoutUpdates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        pageType: PropTypes.string.isRequired,
        product: PropTypes.shape({}).isRequired,
        getLayoutUpdates: PropTypes.func.isRequired,
        isCookieScriptEnabled: PropTypes.bool.isRequired,
        device: PropTypes.shape({
            isMobile: PropTypes.bool,
            android: PropTypes.bool,
            ios: PropTypes.bool,
            blackberry: PropTypes.bool,
            opera: PropTypes.bool,
            safari: PropTypes.bool,
            windows: PropTypes.bool
        }).isRequired
    };

    static defaultProps = {
        base_link_url: '',
        default_description: '',
        default_keywords: '',
        default_title: '',
        title_prefix: '',
        title_suffix: '',
        isLoading: true,
        isBigOffline: false,
        meta_title: '',
        status_code: ''
    };

    componentDidMount() {
        this.initializeApplication();
        window.addEventListener('resize', this.handleResize);
    }

    componentDidUpdate(prevProps) {
        const {
            isLoading,
            updateMeta,
            layoutUpdates,
            pageType,
            getLayoutUpdates
        } = this.props;
        const { isLoading: prevIsLoading } = prevProps;
        const { currentUrl } = this.state;
        if (currentUrl !== window.location.pathname) {
            toggleScroll(true);
            this.setState({ currentUrl: window.location.pathname });
            if (window.om52150_46059) {
                try {
                    window.om52150_46059.reset();
                } catch (e) {
                    console.log(e, 'This is to catch optinmonster errors');
                }
            }

            if (!BrowserDatabase.getItem('config')) {
                this.initializeApplication();
            }
        }

        if (!isLoading && isLoading !== prevIsLoading) {
            const {
                default_description,
                default_keywords,
                default_title,
                title_prefix,
                title_suffix,
                meta_title,
                status_code,
                isNoHreflangs
            } = this.props;

            const { value: metaTitle = meta_title } = meta_title;

            updateMeta({
                default_title,
                title: metaTitle || default_title,
                default_description,
                description: default_description,
                default_keywords,
                keywords: default_keywords,
                title_prefix,
                title_suffix,
                status_code,
                isNoHreflangs
            });
            getLayoutUpdates({ type: pageType, layoutUpdates });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    __construct() {
        this.state = ({
            currentUrl: window.location.pathname
        });

        this.redirectFromPartialUrl();
        this.handleResize();
    }

    handleResize = async () => {
        const { updateConfigDevice } = this.props;

        if (isUsingClientHints) {
            const { platform, model } = await isMobileClientHints.getDeviceData();
            updateConfigDevice({
                isMobile: isMobile.any(),
                android: isMobile.android(platform),
                ios: isMobile.iOS(platform),
                blackberry: isMobile.blackBerry(model),
                opera: isMobile.opera(model),
                safari: isMobile.safari(model),
                windows: isMobile.windows(model)
            });
        } else {
            updateConfigDevice({
                isMobile: isMobile.any(),
                android: isMobile.android(),
                ios: isMobile.iOS(),
                blackberry: isMobile.blackBerry(),
                opera: isMobile.opera(),
                safari: isMobile.safari(),
                windows: isMobile.windows()
            });
        }
    };

    containerProps() {
        const { isBigOffline, isCookieScriptEnabled, device } = this.props;

        return { isBigOffline, isCookieScriptEnabled, device };
    }

    initializeApplication() {
        const { init } = this.props;
        init();
    }

    redirectFromPartialUrl() {
        const { base_link_url } = this.props;
        const { pathname: storePrefix } = new URL(base_link_url || window.location.origin);
        const { pathname } = location;

        if (storePrefix === '/') {
            return;
        }

        if (storePrefix.slice(0, -1) === pathname) {
            history.replace(storePrefix);
        }
    }

    render() {
        return (
            <Router
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
