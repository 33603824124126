/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import {
    createRef,
    lazy,
    PureComponent, Suspense
} from 'react';

import ClickOutside from 'Component/ClickOutside';
import CloseIcon from 'Component/CloseIcon';
import { EVENT_GTM_SITE_SEARCH_STARTED } from 'Component/GoogleTagManager/GoogleTagManager.events';
import {
    GREY_KEY, GREY500_KEY, M_KEY, S_KEY
} from 'Component/Icon/Icon.config';
import Loader from 'Component/Loader';
import SearchIcon from 'Component/SearchIcon';
import TextPlaceholder from 'Component/TextPlaceholder';
import { event } from 'Store/GoogleTagManager/GoogleTagManager.action';
import { DeviceType } from 'Type/Device.type';

import { FIX_SIZE, FULL_SIZE } from './SearchField.config';

import './SearchField.style';

export const SearchOverlay = lazy(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "overlay" */
        'Component/SearchOverlay'
    )
);

/** @namespace Bodypwa/Component/SearchField/Component */
export class SearchFieldComponent extends PureComponent {
    static propTypes = {
        searchCriteria: PropTypes.string,
        onSearchBarFocus: PropTypes.func.isRequired,
        onSearchBarChange: PropTypes.func.isRequired,
        onSearchOutsideClick: PropTypes.func.isRequired,
        onClearSearchButtonClick: PropTypes.func.isRequired,
        isVisible: PropTypes.bool,
        isActive: PropTypes.bool,
        device: DeviceType.isRequired,
        size: PropTypes.oneOf([FIX_SIZE, FULL_SIZE]),
        isUrlRedirectLoading: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        makeSearchRequestForRedirect: PropTypes.func.isRequired
    };

    static defaultProps = {
        isVisible: true,
        isActive: true,
        searchCriteria: '',
        size: 'FULL_SIZE'
    };

    state = {
        isAssigned: false
    };

    // eslint-disable-next-line react/sort-comp
    componentDidMount() {
        if (this.searchBarRef.current) {
            this.searchBarRef.current.focus();
        }
    }

    searchBarRef = createRef();

    onClearSearchButtonClick(isFocusOnSearchBar = true) {
        const { onClearSearchButtonClick } = this.props;

        if (isFocusOnSearchBar) {
            this.searchBarRef.current.focus();
        }
        onClearSearchButtonClick();
    }

    clearSearch = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        this.onClearSearchButtonClick(false);
    };

    renderSearchIcons() {
        const { isActive, searchCriteria, size } = this.props;

        return (
            <div block="SearchField" elem="IconsContainer">
                <div
                  block="SearchField"
                  elem="SearchIcon"
                  role="button"
                  tabIndex="0"
                  aria-label={ __('Search') }
                >
                    <SearchIcon size={ S_KEY } color={ GREY_KEY } />
                </div>
                { isActive && searchCriteria && (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <div
                      block="SearchField"
                      elem="CloseIcon"
                      mods={ { size } }
                      role="button"
                      tabIndex="0"
                      onClick={ this.clearSearch }
                      aria-label={ __('Close') }
                    >
                        <CloseIcon size={ M_KEY } color={ GREY500_KEY } />
                    </div>
                ) }
            </div>
        );
    }

    onSearchFieldClick = (event) => {
        event(EVENT_GTM_SITE_SEARCH_STARTED, {});
    };

    onSearchEnterPress = (e) => {
        const {
            searchCriteria,
            makeSearchRequestForRedirect
        } = this.props;

        const search = searchCriteria.trim().replace(/\s/g, '+');
        const trimmedSearch = searchCriteria.trim();

        if (e.key === 'Enter' && trimmedSearch !== '') {
            this.searchBarRef.current.blur();
            makeSearchRequestForRedirect(search);
        }
    };

    onIconClick = () => {
        this.searchBarRef.current.focus();
    };

    openSearch = () => {
        const { onSearchBarFocus } = this.props;

        onSearchBarFocus();
    };

    closeSearch = () => {
        const { onSearchOutsideClick } = this.props;

        onSearchOutsideClick();
    };

    handleChange = (e) => {
        const { onSearchBarChange } = this.props;
        onSearchBarChange(e);
    };

    closeSearch = () => {
        const { onSearchOutsideClick } = this.props;
        onSearchOutsideClick();
    };

    renderClearSearch() {
        const { isVisible } = this.props;

        return (
            <button
              block="Header"
              elem="Button"
              onClick={ this.onClearSearchButtonClick }
              mods={ {
                  type: 'searchClear',
                  isVisible
            } }
              aria-label="Clear search"
            />
        );
    }

    renderOverlayFallback() {
        return <Loader isLoading />;
    }

    renderSearch() {
        const {
            searchCriteria,
            onSearchBarFocus,
            isActive,
            device,
            onClearSearchButtonClick,
            isUrlRedirectLoading,
            size
        } = this.props;
        const { isAssigned = false } = this.state;
        const { current = false } = this.searchBarRef;
        const self = this;
        if (current && !isAssigned) {
            this.searchBarRef.current
                .addEventListener('focus', () => self.onSearchFieldClick(event));
            this.setState({ isAssigned: true });
        }

        return (
            <>

            <Loader isLoading={ isUrlRedirectLoading } />
                <div
                  block="SearchField"
                  elem="SearchInnerWrapper"
                  mods={ { size } }
                >
                    <input
                      id="search-field"
                      ref={ this.searchBarRef }
                      block="SearchField"
                      elem="Input"
                      onFocus={ onSearchBarFocus }
                      onChange={ this.handleChange }
                      onKeyDown={ this.onSearchEnterPress }
                      value={ searchCriteria }
                      mods={ { isActive, size } }
                      placeholder={ __('Search') }
                      autoComplete="off"
                      aria-label={ __('Search') }
                    />
                    { this.renderSearchIcons() }
                    <Suspense fallback={ this.renderOverlayFallback() }>
                        <SearchOverlay
                          isHideOverlay={ isUrlRedirectLoading || !device?.isMobile }
                          clearSearch={ onClearSearchButtonClick }
                          searchCriteria={ searchCriteria }
                          mods={ { size } }
                        />
                    </Suspense>
                </div>
            </>
        );
    }

    renderSearchIcon() {
        const { isActive } = this.props;

        if (isActive) {
            return (
                <div
                  block="SearchField"
                  elem="CloseIcon"
                  role="button"
                  tabIndex="0"
                  onClick={ this.closeSearch }
                  aria-label={ __('Close') }
                >
                    <CloseIcon />
                </div>
            );
        }

        return (
            <div
              block="SearchField"
              elem="SearchIcon"
              role="button"
              tabIndex="0"
              onClick={ this.openSearch }
              aria-label={ __('Search') }
            >
                <SearchIcon />
            </div>
        );
    }

    render() {
        const {
            isVisible,
            isActive,
            isLoading
        } = this.props;

        if (isLoading) {
            return <TextPlaceholder height="button" length="button" />;
        }

        return (
            <div block="SearchField" mods={ { isVisible, isActive } }>
                <ClickOutside onClick={ this.closeSearch }>
                    <div block="SearchField" elem="Wrapper">
                        { this.renderSearch() }
                    </div>
                </ClickOutside>
            </div>
        );
    }
}

export default SearchFieldComponent;
