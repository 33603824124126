import { PureComponent } from 'react';

import {
    BLACK_KEY,
    BLUE_KEY,
    COLOR_BLACK,
    COLOR_BLUE, COLOR_GREEN, COLOR_GREY, COLOR_GREY300, COLOR_GREY500, COLOR_GREY600, COLOR_RED500,
    COLOR_WHITE, GREEN_KEY,
    GREY_KEY,
    GREY300_KEY,
    GREY500_KEY,
    GREY600_KEY,
    L_KEY,
    L_VALUE, M_KEY,
    M_VALUE, RED400_KEY,
    S_KEY, S_VALUE, WHITE_KEY, XL_KEY, XL_VALUE,
    XS_KEY, XS_VALUE, XXS_KEY,
    XXS_VALUE, XXXS_KEY, XXXS_VALUE
} from './Icon.config';
/** @namespace Bodypwa/Component/Icon/Component */
export class IconComponent extends PureComponent {
    dimensions = {
        [XXXS_KEY]: XXXS_VALUE,
        [XXS_KEY]: XXS_VALUE,
        [XS_KEY]: XS_VALUE,
        [S_KEY]: S_VALUE,
        [M_KEY]: M_VALUE,
        [L_KEY]: L_VALUE,
        [XL_KEY]: XL_VALUE
    };

    colors = {
        [WHITE_KEY]: COLOR_WHITE,
        [BLACK_KEY]: COLOR_BLACK,
        [GREEN_KEY]: COLOR_GREEN,
        [BLUE_KEY]: COLOR_BLUE,
        [GREY_KEY]: COLOR_GREY,
        [GREY300_KEY]: COLOR_GREY300,
        [GREY500_KEY]: COLOR_GREY500,
        [GREY600_KEY]: COLOR_GREY600,
        [RED400_KEY]: COLOR_RED500
    };

    static defaultProps = {
        size: M_KEY,
        color: BLACK_KEY
    };
}
export default IconComponent;
