/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { createPortal } from 'react-dom';

import { ChildrenType } from 'Type/Common.type';
import { toggleScroll } from 'Util/Browser';
import { noopFn } from 'Util/Common';

import './Overlay.style';

/** @namespace Bodypwa/Component/Overlay/Component */
export class OverlayComponent extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/no-unused-prop-types
        id: PropTypes.string.isRequired,
        onVisible: PropTypes.func,
        onHide: PropTypes.func,
        // eslint-disable-next-line react/no-unused-prop-types
        activeOverlay: PropTypes.string.isRequired,
        // eslint-disable-next-line react/boolean-prop-naming
        areOtherOverlaysOpen: PropTypes.bool.isRequired,
        isStatic: PropTypes.bool,
        isRenderInPortal: PropTypes.bool,
        children: ChildrenType,
        isMobile: PropTypes.bool.isRequired,
        scrollToElement: PropTypes.number,
        isFrezeScroll: PropTypes.bool,
        setScrollToElement: PropTypes.func,
        className: PropTypes.string
    };

    static defaultProps = {
        children: [],
        onVisible: noopFn,
        isStatic: false,
        onHide: noopFn,
        isRenderInPortal: true,
        setScrollToElement: noopFn,
        isFrezeScroll: true,
        scrollToElement: 0,
        className: ''
    };

    overlayRef = createRef();

    componentDidUpdate(prevProps) {
        const prevWasVisible = this.getIsVisible(prevProps);
        const isVisible = this.getIsVisible();

        if (isVisible && !prevWasVisible) {
            this.onVisible();
        }

        if (!isVisible && prevWasVisible) {
            this.onHide();
        }
    }

    onVisible() {
        const { onVisible, isStatic, isFrezeScroll = true } = this.props;

        if (isStatic) {
            return;
        }

        if (isFrezeScroll) {
            this.freezeScroll();
        }

        this.overlayRef.current.focus();
        onVisible();
    }

    onHide() {
        const { onHide, isStatic, isFrezeScroll = true } = this.props;

        if (isStatic) {
            return;
        }

        if (isFrezeScroll) {
            this.unfreezeScroll();
        }

        onHide();
    }

    getIsVisible(props = this.props) {
        const { id, activeOverlay, isStatic } = props;

        return isStatic || id === activeOverlay;
    }

    freezeScroll() {
        document.documentElement.style.setProperty(
            '--window-inner-height',
            `${window.innerHeight}px`
        );
        this.YoffsetWhenScrollDisabled = window.pageYOffset || document.body.scrollTop;
        toggleScroll(false);
    }

    unfreezeScroll() {
        toggleScroll(true);
        const { scrollToElement, setScrollToElement } = this.props;
        if (scrollToElement) {
            window.scrollTo(0, scrollToElement);
            setScrollToElement(0);
        } else {
            window.scrollTo(0, this.YoffsetWhenScrollDisabled);
        }
    }

    renderInMobilePortal(content) {
        const { isStatic, isRenderInPortal, isMobile } = this.props;

        if (!isStatic && isMobile && isRenderInPortal) {
            return createPortal(content, document.body);
        }

        return content;
    }

    render() {
        const {
            children,
            areOtherOverlaysOpen,
            isStatic,
            className
        } = this.props;

        const isVisible = this.getIsVisible();

        return this.renderInMobilePortal(
            <div
              className={ `bg-white-normal pointer-events-none will-change-[opacity] z-10 opacity-0
                md:absolute md:transition-opacity md:duration-[200] md:h-0 md:overflow-hidden
                ${areOtherOverlaysOpen}
                ${className}
                ${isStatic ? 'fixed top-[84px] left-0 w-full h-[calc(100%-var(--header-total-height))] pb-[var(--navigation-tabs-height)] overflow-scroll overflow-x-hidden max-w-full' : ''}
                ${isVisible ? 'pointer-events-auto touch-pan-y opacity-100 z-[99] md:h-auto md:overflow-visible' : ''}
                ` }
              ref={ this.overlayRef }
            >
                { children && children }
            </div>
        );
    }
}

export default OverlayComponent;
