/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/** @namespace Bodypwa/Component/Buttons/Component */
import PropTypes from 'prop-types';
import React from 'react';
/** @namespace Bodypwa/Component/Buttons/Component */
export class ButtonsComponent extends React.PureComponent {
    static propTypes = {
        elements: PropTypes.shape({
            BaseButtons: PropTypes.shape({
                propsBag: PropTypes.arrayOf(
                    PropTypes.shape({
                        'data-same-width': PropTypes.string // Adaptează tipul dacă este diferit
                    })
                ).isRequired, // Array-ul trebuie să fie întotdeauna prezent
                childEleBag: PropTypes.arrayOf(PropTypes.node).isRequired, // Noduri React sau JSX
                Ele: PropTypes.elementType.isRequired // Componenta React utilizată ca wrapper
            }).isRequired
        }).isRequired
    };

    componentDidMount() {
        // Destructurează proprietățile pentru a respecta regulile ESLint
        const { elements: { BaseButtons } } = this.props;
        const { propsBag } = BaseButtons;

        // Verifică dacă butoanele necesită aceeași lățime
        const isSameWidth = propsBag && propsBag[0] && propsBag[0]['data-same-width'] === 'true';
        if (isSameWidth) {
            this.equalizeButtonWidth(); // Egalizează lățimea butoanelor
        }
    }

    __construct(props) {
        super.__construct(props);
        this.ref = React.createRef();
    }

    equalizeButtonWidth() {
        // eslint-disable-next-line fp/no-let
        let buttonMinWidth = 0;
        const buttonList = this.ref.current.querySelectorAll('[data-element="link"], [data-element="empty_link"]');
        buttonList.forEach((btn) => {
            const buttonWidth = btn.offsetWidth;
            if (buttonWidth > buttonMinWidth) {
                buttonMinWidth = buttonWidth;
            }
        });
        buttonList.forEach((btn, idx) => {
            buttonList[idx].style['min-width'] = `${buttonMinWidth }px`;
        });
    }

    render() {
        // eslint-disable-next-line react/destructuring-assignment
        const { BaseButtons } = this.props.elements;

        return (
          <BaseButtons.Ele ref={ this.ref }>
                { BaseButtons.childEleBag[0] }
          </BaseButtons.Ele>
        );
    }
}

export default ButtonsComponent;
