export const HEADER_SKELETON_MOBILE = [
    { height: 32, width: '100%', gap: 0 },
    { height: 32, width: '100%', gap: 0 },
    {
        type: 'group',
        flexDirection: 'row',
        width: '100%',
        height: 56,
        gap: 0,
        items: [
            {
                type: 'group',
                flexDirection: 'row',
                alignItems: 'left',
                gap: 16,
                columns: 2,
                style: { paddingLeft: 12 },
                items: [
                    { height: 20, maxWidth: 25 }
                ]
            },
            {
                type: 'group',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 16,
                columns: 1,
                items: [
                    { height: 32, maxWidth: 140 }
                ]
            },
            {
                type: 'group',
                flexDirection: 'row',
                alignItems: 'right',
                gap: 16,
                columns: 2,
                style: { paddingRight: 12 },
                items: [
                    { height: 25, maxWidth: 25 }
                ]
            }
        ]
    }
];

export const HEADER_SKELETON = [
    {
        type: 'group',
        flexDirection: 'column',
        gap: 0,
        items: [
            { height: 32 },
            {
                type: 'group',
                flexDirection: 'row',
                alignItems: 'center',
                maxWidth: 1332,
                gap: 20,
                height: 32,
                columns: 3,
                items: [
                    { height: 13 }
                ]
            },
            {
                type: 'group',
                flexDirection: 'row',
                alignItems: 'center',
                maxWidth: 1332,
                height: 72,
                items: [
                    {
                        type: 'group',
                        alignItems: 'left',
                        items: [
                            { height: 40, maxWidth: 180 }
                        ]
                    },
                    {
                        type: 'group',
                        alignItems: 'right',
                        gap: 16,
                        columns: 4,
                        items: [
                            { height: 16, maxWidth: 75 }
                        ]
                    }
                ]
            },
            {
                type: 'group',
                flexDirection: 'row',
                alignItems: 'center',
                maxWidth: 1332,
                height: 32,
                items: [
                    {
                        type: 'group',
                        flexDirection: 'row',
                        alignItems: 'left',
                        gap: 16,
                        columns: 4,
                        items: [
                            { height: 20, maxWidth: 100 }
                        ]
                    },
                    {
                        type: 'group',
                        flexDirection: 'row',
                        alignItems: 'right',
                        gap: 16,
                        columns: 4,
                        items: [
                            { height: 25, maxWidth: 25 }
                        ]
                    }

                ]
            }
        ]
    }
];
