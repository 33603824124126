/* eslint-disable max-len */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';

import Image from 'Component/Image/Image.component';
import {
    IMAGE_NOT_FOUND,
    IMAGE_NOT_SPECIFIED
} from 'Component/Image/Image.config';
import logo from 'Style/icons/logos/mainLogo.svg';

import './Logo.style';

/** @namespace Bodypwa/Component/Logo/Component */
export class LogoComponent extends Image {
    static propTypes = {
        className: PropTypes.string,
        imageCustomClass: PropTypes.string
    };

    static defaultProps = {
        className: '',
        imageCustomClass: ''
    };

    renderPlaceholderLogo() {
        const { imageCustomClass } = this.props;
        return (
            <div
              className="flex left-0 right-0 top-0 bottom-0 m-auto h-full"
            >
                <Image
                  src={ logo }
                  alt="LogoPlaceholder"
                  ratio="custom"
                  imageCustomClass={ imageCustomClass }
                />
            </div>
        );
    }

    renderImage() {
        const { imageStatus } = this.state;
        const { src } = this.props;

        if (!src) {
            return this.renderPlaceholderLogo();
        }

        switch (imageStatus) {
        case IMAGE_NOT_FOUND:
        case IMAGE_NOT_SPECIFIED:
            return this.renderPlaceholderLogo();
        default:
            return super.renderImage();
        }
    }

    render() {
        const { imageStatus } = this.state;
        const { className } = this.props;
        return (
            <div
              className={ `bg-transparent ${className}` }
              mods={ { imageStatus } }
              mix={ { block: 'Logo' } }
            >
                { this.renderImage() }
            </div>
        );
    }
}

export default LogoComponent;
