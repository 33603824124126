import { connect } from 'react-redux';

import DataContainer from 'Util/Request/DataContainer';

import DynamicBlockQuery from '../../query/DynamicBlock.query';
import DynamicBlock from './DynamicBlock.component';
/** @namespace Bodypwa/Component/DynamicBlock/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    baseUrl: state.ConfigReducer.baseUrl
});

/** @namespace Bodypwa/Component/DynamicBlock/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({
});

/** @namespace Bodypwa/Component/DynamicBlock/Container */
export class DynamicBlockContainer extends DataContainer {
  static propTypes = {};

  state = {
      content: undefined
  };

  containerProps = () => {
      const { content } = this.state;
      return { content, ...this.props };
  };

  componentDidMount() {
      this.fetchDynamicBlock();
  }

  fetchDynamicBlock() {
      const {
          BannerWidget
      } = this.props.elements;

      const id = BannerWidget.propsBag[0]['data-banner-id'];
      this.fetchData(
          [DynamicBlockQuery.getQuery({ id })],
          ({ dynamicBlock }) => {
              if (!dynamicBlock || Object.keys(dynamicBlock).length === 0) {
                  return;
              }
              this.setState({ content: dynamicBlock.content });
          }
      );
  }

  render() {
      return (
      <DynamicBlock
        { ...this.containerProps() }
      />
      );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DynamicBlockContainer);
