/* eslint-disable react/destructuring-assignment */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/prop-types */
import React from 'react';

import Html from 'Component/Html';
/** @namespace Bodypwa/Component/DynamicBlock/Component */
export class DynamicBlockComponent extends React.PureComponent {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    __construct(props) {
        super.__construct(props);
    }

    render() {
        const {
            BaseDynamicBlock,
            BannerWidget,
            BannerItems,
            BannerItem,
            BannerItemContent
        // eslint-disable-next-line react/destructuring-assignment
        } = this.props.elements;

        // eslint-disable-next-line react/destructuring-assignment
        if (!this.props.content) {
            return null;
        }

        return (
<BaseDynamicBlock.Ele>
      <BannerWidget.Ele>
        <BannerItems.Ele>
          { BannerItem.propsBag.map((props, idx) => (
            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction, react/no-array-index-key
            <BannerItem.Ele { ...props } key={ `banner-item-${idx}` }>
              <BannerItemContent.Ele { ...BannerItemContent.propsBag[idx] }>
                <Html content={ this.props.content } baseUrl={ this.props.baseUrl } />
              </BannerItemContent.Ele>
            </BannerItem.Ele>
          )) }
        </BannerItems.Ele>
      </BannerWidget.Ele>
</BaseDynamicBlock.Ele>
        );
    }
}

export default DynamicBlockComponent;
