import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { EVENT_TRANSLATE_PAGE } from 'Component/GoogleTagManager/GoogleTagManager.events';
import { event } from 'Store/GoogleTagManager/GoogleTagManager.action';
import {
    fetchQuery
} from 'Util/Request';

import I18nQuery from '../../query/I18n.query';
import I18n from './I18n.component';
import { BODYAPP_LOCALE } from './I18n.config';
/** @namespace Bodypwa/Component/I18n/Container/mapStateToProps */
export const mapStateToProps = () => ({
});

/** @namespace Bodypwa/Component/I18n/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    event: (eventName = '', customData) => dispatch(event(eventName, customData))
});

/** @namespace Bodypwa/Component/I18n/Container */
export class I18nContainer extends PureComponent {
    static propTypes = {
        event: PropTypes.func.isRequired
    };

    state = {
        locale: 'en_US'
    };

    componentDidMount() {
        this.setLocaleFromRequest();
    }

    setLocaleFromRequest() {
        const req = new XMLHttpRequest();
        req.open('GET', document.location, true);
        req.onload = () => {
            const headers = req.getAllResponseHeaders().split('\n');
            const localeHeader = headers?.filter((header) => header?.includes(BODYAPP_LOCALE));
            const storeLocale = localeHeader[0]?.replace(`${BODYAPP_LOCALE}: `, '')?.replace('\r', '');

            if (storeLocale) {
                this.setState({ locale: storeLocale });
                document.documentElement.lang = this.getLanguageCode(storeLocale);
                this.observeLangChange(document.documentElement.lang);
            } else {
                this.setLocale();
            }
        };

        req.send();
    }

    observeLangChange() {
        const { event } = this.props;
        this.htmlElement = document.documentElement;
        const currenLang = this.htmlElement.getAttribute('lang');
        this.langObserver = new MutationObserver((mutationsList) => {
            // eslint-disable-next-line no-restricted-syntax
            for (const mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'lang') {
                    const newLang = this.htmlElement.getAttribute('lang');
                    if (newLang !== currenLang) {
                        event(EVENT_TRANSLATE_PAGE, {
                            language: newLang,
                            prevLang: currenLang
                        });
                    }
                }
            }
        });
        this.langObserver.observe(this.htmlElement, { attributes: true, attributeFilter: ['lang'] });
    }

    async setLocale() {
        const { storeConfig: { locale } } = await fetchQuery(
            I18nQuery.getQuery()
        );

        document.documentElement.lang = this.getLanguageCode(locale);
        this.setState({ locale });
        this.observeLangChange();
    }

    getLanguageCode(lang) {
        return lang.substring(0, 2);
    }

    render() {
        const { locale } = this.state;
        return (
            <I18n
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              locale={ locale }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(I18nContainer);
