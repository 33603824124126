/* eslint-disable max-lines */
import { event } from '@scandipwa/gtm-new/src/store/GoogleTagManager/GoogleTagManager.action';
import { CHECKOUT_ACCOUNT } from '@scandipwa/scandipwa/src/component/Header/Header.config';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CATEGORY_FILTER_OVERLAY_ID } from 'Component/CategoryFilterOverlay/CategoryFilterOverlay.config';
import { CUSTOMER_ACCOUNT_OVERLAY_KEY } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import { DEFAULT_STATE_NAME } from 'Component/NavigationAbstract/NavigationAbstract.config';
import { NavigationAbstractContainer } from 'Component/NavigationAbstract/NavigationAbstract.container';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import { ACCOUNT_LOGIN_URL } from 'Route/MyAccount/MyAccount.config';
import { setIsCheckout } from 'Store/Checkout/Checkout.action';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import {
    setHeaderElement,
    setHeaderHeight, setHeaderHidden, setHeaderPlaceholderHeight,
    setHeaderTopRef,
    setWindowHeight
} from 'Store/Ui/Ui.action';
import { DeviceType } from 'Type/Device.type';
import { isSignedIn } from 'Util/Auth';
import { scrollToTop, toggleScroll } from 'Util/Browser';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { checkAppVersionFromHeader } from 'Util/Cache/VersionCheck';
import componentLoader from 'Util/componentLoader';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import Header from './Header.component';
import {
    CART,
    CART_OVERLAY, CATEGORY,
    CHECKOUT, CHECKOUT_SUCCESS,
    CMS_PAGE, CUSTOMER_ACCOUNT,
    CUSTOMER_ACCOUNT_PAGE, CUSTOMER_SUB_ACCOUNT,
    MENU, MY_ACCOUNT,
    PDP, SEARCH
} from './Header.config';

export const MyAccountDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
), 2);

export const SideMenuDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/SideMenu/SideMenu.dispatcher'
), 2);

export const MobileSearchBarDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MobileSearchBar/MobileSearchBar.dispatcher'
), 2);

export const SearchBarDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/SearchBar/SearchBar.dispatcher'
), 2);

/** @namespace Bodypwa/Component/Header/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    navigationState: state.NavigationReducer[TOP_NAVIGATION_TYPE].navigationState,
    cartTotals: state.CartReducer.cartTotals,
    header_logo_src: state.ConfigReducer.header_logo_src,
    isOffline: state.OfflineReducer.isOffline,
    logo_alt: state.ConfigReducer.logo_alt,
    logo_height: state.ConfigReducer.logo_height,
    logo_width: state.ConfigReducer.logo_width,
    isLoading: state.ConfigReducer.isLoading,
    device: state.ConfigReducer.device,
    activeOverlay: state.OverlayReducer.activeOverlay,
    isWishlistLoading: state.WishlistReducer.isLoading,
    topPageLinks: state.ConfigReducer.bms_header_links,
    activeStep: state.CheckoutReducer.activeStep,
    headerMenu: state.ConfigReducer.header_menu,
    headerHeight: state.UiReducer.headerHeight,
    headerHidden: state.UiReducer.headerHidden,
    headerPlaceholderHeight: state.UiReducer.headerPlaceholderHeight,
    updateScroll: state.UiReducer.updateScroll,
    hideWishlistButton: !state.ConfigReducer.wishlist_general_active,
    isSearchBarActive: state.MobileSearchBarReducer.isActive
});

/** @namespace Bodypwa/Component/Header/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    setNavigationState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    setHeaderHeight: (height) => dispatch(setHeaderHeight(height)),
    setIsCheckout: () => dispatch(setIsCheckout()),
    setWindowHeight: (windowHeight) => dispatch(setWindowHeight(windowHeight)),
    setHeaderHidden: (headerHidden) => dispatch(setHeaderHidden(headerHidden)),
    setHeaderPlaceholderHeight:
     (headerPlaceholderHeight) => dispatch(setHeaderPlaceholderHeight(headerPlaceholderHeight)),
    setHeaderElement: (headerElement) => dispatch(setHeaderElement(headerElement)),
    setHeaderTopRef: (headerTopRef) => dispatch(setHeaderTopRef(headerTopRef)),
    validateSession: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.validateSession(dispatch)
    ),
    openSideMenu: () => SideMenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.openSideMenu(dispatch)
    ),
    updateLoadStatus: (options) => SearchBarDispatcher.then(
        ({ default: dispatcher }) => dispatcher.onError(options, dispatch)
    ),
    clearSearchResults: () => SearchBarDispatcher.then(
        ({ default: dispatcher }) => dispatcher.clearSearchResults(dispatch)
    ),
    activateSearchBar: () => MobileSearchBarDispatcher.then(
        ({ default: dispatcher }) => dispatcher.activateSearchBar(dispatch)
    ),
    deactivateSearchBar: () => MobileSearchBarDispatcher.then(
        ({ default: dispatcher }) => dispatcher.deactivateSearchBar(dispatch)
    ),
    event: (eventName = '', customData) => dispatch(event(eventName, customData))
});

export const DEFAULT_HEADER_STATE = {
    name: DEFAULT_STATE_NAME,
    isHiddenOnMobile: false
};

/** @namespace Bodypwa/Component/Header/Container */
export class HeaderContainer extends NavigationAbstractContainer {
    static propTypes = {
        showOverlay: PropTypes.func.isRequired,
        isWishlistLoading: PropTypes.bool.isRequired,
        goToPreviousNavigationState: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        header_logo_src: PropTypes.string,
        device: DeviceType.isRequired
    };

    static defaultProps = {
        header_logo_src: ''
    };

    default_state = DEFAULT_HEADER_STATE;

    routeMap = {
        // eslint-disable-next-line max-len
        '/account/confirm': { name: CMS_PAGE, title: __('Confirm account'), onBackClick: () => history.push(appendWithStoreCode('/')) },
        '/category': { name: CATEGORY },
        '/checkout/success': { name: CHECKOUT_SUCCESS },
        '/checkout': { name: CHECKOUT, onBackClick: () => history.push(appendWithStoreCode('/cart')) },
        '/my-account': { name: CUSTOMER_ACCOUNT_PAGE, onBackClick: () => history.push(appendWithStoreCode('/')) },
        '/product': { name: PDP, onBackClick: () => history.goBack() },
        '/cart': { name: CART },
        '/menu': { name: MENU },
        '/page': { name: CMS_PAGE, onBackClick: () => history.goBack() },
        '/': { name: DEFAULT_STATE_NAME, isHiddenOnMobile: true }
    };

    containerFunctions = {
        onBackButtonClick: this.onBackButtonClick.bind(this),
        onCloseButtonClick: this.onCloseButtonClick.bind(this),
        onSearchBarFocus: this.onSearchBarFocus.bind(this),
        onClearSearchButtonClick: this.onClearSearchButtonClick.bind(this),
        onMyAccountButtonClick: this.onMyAccountButtonClick.bind(this),
        onSearchBarChange: this.onSearchBarChange.bind(this),
        onEditButtonClick: this.onEditButtonClick.bind(this),
        onMinicartButtonClick: this.onMinicartButtonClick.bind(this),
        onOkButtonClick: this.onOkButtonClick.bind(this),
        onCancelButtonClick: this.onCancelButtonClick.bind(this),
        onSearchOutsideClick: this.onSearchOutsideClick.bind(this),
        onMyAccountOutsideClick: this.onMyAccountOutsideClick.bind(this),
        onMinicartOutsideClick: this.onMinicartOutsideClick.bind(this),
        onSignIn: this.onSignIn.bind(this),
        onMobileMyAccountButtonClick: this.onMobileMyAccountButtonClick.bind(this),
        onSearchButtonClick: this.onSearchButtonClick.bind(this),
        onWishlistClick: this.onWishlistClick.bind(this),
        onSearchBarDeactivate: this.onSearchBarDeactivate.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            prevPathname: '',
            searchCriteria: '',
            isClearEnabled: this.getIsClearEnabled(),
            scrollDistance: 0,
            showMyAccountLogin: false,
            prevScroll: 0
        };

        this.scrollEndTimeout = null;
    }

    containerAdditionalFunctions = {
        setIsCheckout: this.props.setIsCheckout
    };

    componentDidMount() {
        const { setNavigationState } = this.props;
        setNavigationState(this.getNavigationState());
        history.listen((history) => {
            this.handlePageScroll();
            this.setState(this.onRouteChanged(history));
        });
    }

    componentDidUpdate() {
        const {
            setIsCheckout, setWindowHeight
        } = this.props;

        setIsCheckout();
        setWindowHeight(window.visualViewport?.height || window.innerHeight);
    }

    getNavigationState() {
        const { navigationState } = this.props;

        const { pathname } = location;
        const { state: historyState } = window.history || {};
        const { state = {} } = historyState || {};

        // TODO: something here breaks /<STORE CODE> from being opened, and / when, the url-based stores are enabled.
        const activeRoute = Object.keys(this.routeMap)
            .find((route) => (
                route !== '/'
                || pathname === appendWithStoreCode('/')
                || pathname === '/'
            ) && pathname.includes(route));

        if (state.category || state.product || state.page || state.popupOpen) { // keep state if it category is in state
            return navigationState;
        }

        return this.routeMap[activeRoute] || this.default_state;
    }

    getUserName() {
        const { firstname } = BrowserDatabase.getItem(CUSTOMER) || {};

        return firstname;
    }

    hideSearchOnStateChange(prevProps) {
        const { navigationState: { name: prevName } } = prevProps;
        const { navigationState: { name } } = this.props;

        if (prevName === SEARCH && prevName !== name) {
            this.hideSearchOverlay();
        }
    }

    hideSearchOverlay() {
        const { hideActiveOverlay, activeOverlay } = this.props;

        this.setState({ searchCriteria: '' });

        if (activeOverlay === SEARCH) {
            document.activeElement.blur();
            hideActiveOverlay();
        }
    }

    handleHeaderVisibility() {
        const { navigationState: { isHiddenOnMobile } } = this.props;

        if (isHiddenOnMobile) {
            document.documentElement.classList.add('hiddenHeader');

            return;
        }

        document.documentElement.classList.remove('hiddenHeader');
    }

    handleMobileUrlChange(history) {
        const { prevPathname } = this.state;
        const { pathname } = history;
        const isClearEnabled = this.getIsClearEnabled();

        if (prevPathname === pathname) {
            return { isClearEnabled };
        }

        return {
            isClearEnabled,
            showMyAccountLogin: false
        };
    }

    getIsClearEnabled() {
        const { location: { search } } = history;

        return new RegExp([
            'customFilters',
            'priceMax',
            'priceMin'
        ].join('|')).test(search);
    }

    onBackButtonClick(e) {
        const { navigationState: { onBackClick } } = this.props;

        this.setState({ searchCriteria: '' });

        if (onBackClick) {
            onBackClick(e);
        }
    }

    onCloseButtonClick(e) {
        const { hideActiveOverlay, goToPreviousNavigationState } = this.props;
        const { navigationState: { onCloseClick } } = this.props;

        this.setState({ searchCriteria: '' });

        if (onCloseClick) {
            onCloseClick(e);
        }

        hideActiveOverlay();
        goToPreviousNavigationState();
    }

    onSearchOutsideClick() {
        const {
            navigationState: { name },
            clearSearchResults,
            deactivateSearchBar,
            setNavigationState
        } = this.props;

        if (name === SEARCH) {
            clearSearchResults();

            deactivateSearchBar();
            this.hideSearchOverlay();

            setNavigationState({
                name: MENU
            });
        }
    }

    onSearchBarFocus() {
        const {
            setNavigationState,
            goToPreviousNavigationState,
            showOverlay,
            navigationState: { name },
            device
        } = this.props;

        if (
            (!device.isMobile && name === SEARCH)
            || (device.isMobile)
        ) {
            return;
        }

        showOverlay(SEARCH);

        setNavigationState({
            name: SEARCH,
            onBackClick: () => {
                showOverlay(MENU);
                goToPreviousNavigationState();
            }
        });
    }

    onSearchBarChange({ target: { value: searchCriteria } }) {
        this.setState({ searchCriteria });
    }

    onClearSearchButtonClick() {
        this.setState({ searchCriteria: '' });
    }

    componentWillUnmount() {
        if (this.langObserver) {
            this.langObserver.disconnect();
        }
        if (super.componentWillUnmount) {
            super.componentWillUnmount();
        }
    }

    containerProps() {
        const {
            activeOverlay,
            navigationState,
            cartTotals,
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width,
            isLoading,
            device,
            isWishlistLoading,
            topPageLinks,
            activeStep,
            setHeaderHeight,
            headerPlaceholderHeight,
            headerHeight,
            setHeaderHight,
            hideWishlistButton,
            setHeaderPlaceholderHeight,
            updateScroll,
            setHeaderElement,
            setHeaderTopRef,
            headerMenu,
            openSideMenu,
            closeSideMenu,
            clearSearchResults,
            isSearchBarActive,
            hideActiveOverlay,
            setIsCheckout
        } = this.props;

        const {
            isClearEnabled,
            searchCriteria,
            showMyAccountLogin,
            shouldRenderCartOverlay
        } = this.state;

        const {
            location: {
                pathname
            }
        } = history;

        const isCheckout = pathname.includes(CHECKOUT_URL);

        return {
            activeOverlay,
            navigationState,
            cartTotals,
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width,
            isLoading,
            isClearEnabled,
            searchCriteria,
            isCheckout,
            showMyAccountLogin,
            device,
            isWishlistLoading,
            shouldRenderCartOverlay,
            topPageLinks,
            activeStep,
            setHeaderHeight,
            headerPlaceholderHeight,
            headerHeight,
            firstname: this.getUserName(),
            setHeaderHight,
            hideWishlistButton,
            setHeaderPlaceholderHeight,
            updateScroll,
            setHeaderElement,
            setHeaderTopRef,
            headerMenu,
            openSideMenu,
            closeSideMenu,
            hideActiveOverlay,
            isSearchBarActive,
            clearSearchResults,
            setIsCheckout
        };
    }

    onMyAccountButtonClick() {
        const {
            showOverlay,
            setNavigationState,
            device: { isMobile } = {}
        } = this.props;
        const { location: { pathname } } = history;
        const isLogin = pathname.includes('/login');
        const isRegister = pathname.includes('/account/create');

        if (!isMobile && (isLogin || isRegister)) {
            return;
        }

        if (isSignedIn()) {
            history.push({ pathname: appendWithStoreCode('/my-account/my-orders') });
            return;
        }

        this.setState({ showMyAccountLogin: true }, () => {
            showOverlay(CUSTOMER_ACCOUNT_OVERLAY_KEY);
            setNavigationState({
                name: CHECKOUT_ACCOUNT,
                title: 'Sign in',
                onCloseClick: this.closeOverlay
            });
        });
    }

    onMyAccountOutsideClick() {
        const {
            goToPreviousNavigationState,
            hideActiveOverlay,
            navigationState: { name },
            device
        } = this.props;

        if (device.isMobile || ![CUSTOMER_ACCOUNT, CUSTOMER_SUB_ACCOUNT, CHECKOUT_ACCOUNT].includes(name)) {
            return;
        }

        if (name === CUSTOMER_SUB_ACCOUNT) {
            goToPreviousNavigationState();
        }

        this.goToDefaultHeaderState();
        hideActiveOverlay();
    }

    closeOverlay = () => {
        const { location: { pathname } } = history;

        if (pathname.includes(CHECKOUT_URL)) {
            this.setState({ showMyAccountLogin: false });
        }
    };

    onSignIn() {
        const { location: { pathname } } = history;

        goToPreviousNavigationState();

        if (pathname.includes(CHECKOUT_URL)) {
            this.setState({ showMyAccountLogin: false });
        }
    }

    onWishlistClick() {
        const { showNotification } = this.props;
        if (!isSignedIn()) {
            return showNotification('info', __('You must login or register to add items to your wishlist.'));
        }

        return false;
    }

    handleDesktopRouteChange() {
        const {
            hideActiveOverlay,
            setNavigationState,
            activeOverlay
        } = this.props;

        setNavigationState(this.routeMap['/']);
        if (activeOverlay !== CATEGORY_FILTER_OVERLAY_ID) {
            hideActiveOverlay();
        }

        return {};
    }

    onSearchBarDeactivate() {
        const { deactivateSearchBar, setNavigationState } = this.props;

        deactivateSearchBar();
        this.hideSearchOverlay();
        toggleScroll(true);
        setNavigationState({
            name: MENU
        });
    }

    onRouteChanged(history) {
        const { device, validateSession } = this.props;

        if (isSignedIn()) {
            // check if token is expired and logout
            validateSession();
        }
        this.onSearchBarDeactivate(this);

        const req = new XMLHttpRequest();
        req.open('GET', document.location, true);
        req.onload = () => {
            // eslint-disable-next-line no-magic-numbers
            if (req.status === 405 || req.status === 503) {
                navigator.serviceWorker.getRegistrations().then(
                    /** @namespace Bodypwa/Component/Header/Container/HeaderContainer/onRouteChanged/getRegistrations/then */
                    (registrations) => {
                        // eslint-disable-next-line no-restricted-syntax
                        for (const registration of registrations) {
                            registration.unregister();
                        }
                    }
                );
                document.addEventListener('activate', (event) => {
                    event.waitUntil(
                        caches.keys().then(
                            /** @namespace Bodypwa/Component/Header/Container/HeaderContainer/onRouteChanged/document/addEventListener/catch/event/waitUntil/keys/then */
                            (keyList) => Promise.all(
                                keyList.map((key) => caches.delete(key))
                            )
                        )
                    );
                });
                caches.keys().then(
                    /** @namespace Bodypwa/Component/Header/Container/HeaderContainer/onRouteChanged/keys/then */
                    (keyList) => Promise.all(
                        keyList.map((key) => caches.delete(key))
                    ).then(
                        /** @namespace Bodypwa/Component/Header/Container/HeaderContainer/onRouteChanged/keys/then/all/then */
                        () => {
                            window.location.reload();
                        }
                    )
                );
            }
        };
        req.send(null);

        document.documentElement.classList.remove('om-position-popup');
        checkAppVersionFromHeader();
        if (!device.isMobile) {
            return this.handleDesktopRouteChange(history);
        }

        return this.handleMobileUrlChange(history);
    }

    onMinicartButtonClick() {
        const {
            showOverlay,
            navigationState: { name },
            device
        } = this.props;

        if (name === CART_OVERLAY) {
            return;
        }

        if (!device.isMobile) {
            this.setState({ shouldRenderCartOverlay: true });

            showOverlay(CART_OVERLAY);

            return;
        }

        history.push(appendWithStoreCode(`/${ CART }`));
    }

    onMinicartOutsideClick() {
        const {
            goToPreviousNavigationState,
            hideActiveOverlay,
            navigationState: { name },
            device
        } = this.props;

        if (device.isMobile || name !== CART_OVERLAY) {
            return;
        }

        goToPreviousNavigationState();
        hideActiveOverlay();
    }

    onEditButtonClick(e) {
        const { navigationState: { onEditClick } } = this.props;

        if (onEditClick) {
            onEditClick(e);
        }
    }

    onOkButtonClick(e) {
        const {
            navigationState: { onOkClick, shouldNotGoToPrevState = false },
            goToPreviousNavigationState
        } = this.props;

        if (onOkClick) {
            onOkClick(e);
        }

        if (!shouldNotGoToPrevState) {
            goToPreviousNavigationState();
        }
    }

    onCancelButtonClick() {
        const {
            navigationState: { onCancelClick },
            goToPreviousNavigationState
        } = this.props;

        if (onCancelClick) {
            onCancelClick();
        }

        goToPreviousNavigationState();
    }

    onSearchButtonClick() {
        const {
            setNavigationState,
            goToPreviousNavigationState,
            showOverlay,
            updateLoadStatus,
            activateSearchBar,
            clearSearchResults
        } = this.props;

        clearSearchResults();
        updateLoadStatus(false);
        showOverlay(SEARCH);
        activateSearchBar();
        this.setState({ searchCriteria: '' });
        toggleScroll(false);
        setNavigationState({
            name: SEARCH,
            onBackClick: () => {
                showOverlay(MENU);
                goToPreviousNavigationState();
            }
        });
    }

    onMobileMyAccountButtonClick() {
        const { pathname } = location;
        const url = appendWithStoreCode(isSignedIn() ? `/${ MY_ACCOUNT }` : ACCOUNT_LOGIN_URL);

        if (pathname !== url) {
            history.push(url);
        }

        scrollToTop();
    }

    render() {
        return (
            <Header
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
