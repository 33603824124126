export const base_cms_blocks = [
    'social_reviews_top',
    'pwa-header-labels-desktop',
    'promotions_top_block',
    'top-footer-block',
    'footer_newsletter_block',
    'legal-links',
    'after-footer-content-block',
    'misc_scripts_block',
    'container-footer-copyright-disclaimer'
];
