import { PureComponent } from 'react';

import Html from 'Component/Html';

import { WidgetType } from '../../type/Widget.type';

import './Widget.style';

/** @namespace Bodypwa/Component/Widget/Component */
export class WidgetComponent extends PureComponent {
    static propTypes = {
        widget: WidgetType.isRequired,
        baseUrl: String.isRequired
    };

    render() {
        const { baseUrl, widget: { params: { block_content } } } = this.props;

        if (block_content) {
            return (
                <div className="CmsBlock-Wrapper CmsBlock-Wrapper_type_">
                    <Html content={ block_content } baseUrl={ baseUrl } />
                </div>
            );
        }

        return null;
    }
}

export default WidgetComponent;
