/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */

import PropTypes from 'prop-types';
import React, { createRef, PureComponent } from 'react';
/** @namespace Bodypwa/Component/LazyBackground/Component */
export class LazyBackgroundComponent extends PureComponent {
    static propTypes = {
        uniqClassName: PropTypes.string.isRequired,
        desktopBg: PropTypes.string.isRequired,
        mobileBg: PropTypes.string.isRequired,
        children: PropTypes.node.isRequired,
        className: PropTypes.string.isRequired
    };

    componentDidMount() {
        const { desktopBg, mobileBg, uniqClassName } = this.props;

        const addBackgroundStyles = () => {
            let css = '';
            if (desktopBg) {
                css += `
                    .${uniqClassName} {
                        background-image: url(${desktopBg});
                    }
                `;
            }
            if (mobileBg) {
                css += `
                    @media only screen and (max-width: 810px) {
                        .${uniqClassName} {
                            background-image: url(${mobileBg});
                        }
                    }
                `;
            }
            if (css) {
                const style = document.createElement('style');
                style.type = 'text/css';
                if (style.styleSheet) {
                    style.styleSheet.cssText = css;
                } else {
                    style.appendChild(document.createTextNode(css));
                }
                document.head.appendChild(style);
            }
        };

        const preloadImage = () => {
            const img = new Image();
            // eslint-disable-next-line no-magic-numbers
            const bgImage = window.innerWidth <= 810 && mobileBg ? mobileBg : desktopBg;
            if (bgImage) {
                img.src = bgImage;
                img.onload = () => {
                    this.setState({ isImageLoaded: true });
                };
            }
        };

        if ('IntersectionObserver' in window) {
            this.observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        addBackgroundStyles();
                        preloadImage();

                        if (this.observer && this.ref.current) {
                            this.observer.unobserve(this.ref.current);
                        }
                    }
                });
            });
            if (this.ref.current) {
                this.observer.observe(this.ref.current);
            }
        } else {
            addBackgroundStyles();
            preloadImage();
        }
    }

    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

     __construct(props) {
        super.__construct(props);
        this.ref = createRef();
        this.state = {
            isImageLoaded: false
        };
    }

    render() {
        const {
            uniqClassName, className, children, ...rest
        } = this.props;
        const { isImageLoaded } = this.state;

        const fadeIn = rest['data-background-images']
        && rest['data-background-images'] !== '{}' ? 'fade-in' : '';
        // eslint-disable-next-line no-nested-ternary
        const fadeInClass = isImageLoaded ? fadeIn : fadeIn ? 'opacity-0' : '';
        return (
            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            <div { ...rest } ref={ this.ref } className={ `${uniqClassName}  ${fadeInClass} ${className}` }>
                { children }
            </div>
        );
    }
}

export default LazyBackgroundComponent;
