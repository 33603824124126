import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import TextPlaceholder from 'Component/TextPlaceholder';
import SearchBarReducer from 'Store/SearchBar/SearchBar.reducer';
import { DeviceType } from 'Type/Device.type';
import { ItemsType } from 'Type/ProductList.type';
import { isScrollDisabled, scrollToTop, toggleScroll } from 'Util/Browser';
import componentLoader from 'Util/componentLoader';
import { withReducers } from 'Util/DynamicReducer';
import history from 'Util/History';
import { appendWithStoreCode, removeBaseUrl } from 'Util/Url';

import SearchField from './SearchField.component';
import { FIX_SIZE, FULL_SIZE } from './SearchField.config';

export const SearchBarDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/SearchBar/SearchBar.dispatcher'
), 2);
export const MobileSearchBarDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MobileSearchBar/MobileSearchBar.dispatcher'
), 2);

/** @namespace Bodypwa/Component/SearchField/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    activeOverlay: state.OverlayReducer.activeOverlay,
    searchResults: state.SearchBarReducer?.productsInSearch,
    redirectUrl: state.SearchBarReducer?.redirectUrl,
    isUrlRedirectLoading: state.SearchBarReducer?.isUrlRedirectLoading,
    base_url: state.ConfigReducer?.base_url,
    device: state.ConfigReducer.device
});

/** @namespace Bodypwa/Component/SearchField/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    makeSearchRequest: (options) => SearchBarDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, options)
    ),
    clearSearchResults: () => SearchBarDispatcher.then(
        ({ default: dispatcher }) => dispatcher.clearSearchResults(dispatch)
    ),
    clearRedirectUrl: () => SearchBarDispatcher.then(
        ({ default: dispatcher }) => dispatcher.clearRedirectUrl(dispatch)
    ),
    updateIsUrlRedirectLoading: (IsUrlRedirectLoading) => SearchBarDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateIsUrlRedirectLoading(dispatch, IsUrlRedirectLoading)
    ),
    deactivateSearchBar: () => MobileSearchBarDispatcher.then(
        ({ default: dispatcher }) => dispatcher.deactivateSearchBar(dispatch)
    ),
    event: (eventName = '', customData) => dispatch(event(eventName, customData))
});

/** @namespace Bodypwa/Component/SearchField/Container */
export class SearchFieldContainer extends PureComponent {
    static propTypes = {
        deactivateSearchBar: PropTypes.func.isRequired,
        device: DeviceType.isRequired,
        isMobile: PropTypes.bool.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        isActive: PropTypes.bool.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onClearSearchButtonClick: PropTypes.func.isRequired,
        onSearchBarChange: PropTypes.func.isRequired,
        onSearchBarFocus: PropTypes.func.isRequired,
        onSearchOutsideClick: PropTypes.func.isRequired,
        searchCriteria: PropTypes.string.isRequired,
        event: PropTypes.func.isRequired,
        makeSearchRequest: PropTypes.func.isRequired,
        clearSearchResults: PropTypes.func.isRequired,
        searchResults: ItemsType.isRequired,
        makeSearchRequestForRedirect: PropTypes.func.isRequired,
        redirectUrl: PropTypes.string.isRequired,
        clearRedirectUrl: PropTypes.func.isRequired,
        base_url: PropTypes.string.isRequired,
        updateIsUrlRedirectLoading: PropTypes.func.isRequired,
        isUrlRedirectLoading: PropTypes.bool.isRequired,
        size: PropTypes.oneOf([FIX_SIZE, FULL_SIZE]),
        isSkeleton: PropTypes.bool
    };

    static defaultProps = {
        size: 'FULL_SIZE',
        isSkeleton: false
    };

    state = {
        activeSearch: ''
    };

    containerFunctions = {
        makeSearchRequestForRedirect: this.makeSearchRequestForRedirect.bind(this)
    };

    componentDidUpdate() {
        const { isUrlRedirectLoading, searchResults } = this.props;
        const { scrollTop } = this.state;
        if (scrollTop) {
            scrollToTop();
            this.setState({ scrollTop: false });
        }
        if (isUrlRedirectLoading && searchResults.length) {
            this.handleRedirect();
        }
    }

    containerProps() {
        const {
            deactivateSearchBar,
            device,
            isMobile,
            hideActiveOverlay,
            isActive,
            isVisible,
            onClearSearchButtonClick,
            onSearchBarChange,
            onSearchBarFocus,
            onSearchOutsideClick,
            searchCriteria,
            event,
            searchResults,
            makeSearchRequestForRedirect,
            isUrlRedirectLoading,
            size,
            isSkeleton
        } = this.props;

        return {
            deactivateSearchBar,
            device,
            isMobile,
            hideActiveOverlay,
            isActive,
            isVisible,
            onClearSearchButtonClick,
            onSearchBarChange,
            onSearchBarFocus,
            onSearchOutsideClick,
            searchCriteria,
            event,
            isUrlRedirectLoading,
            searchResults,
            makeSearchRequestForRedirect,
            size,
            isSkeleton
        };
    }

    makeSearchRequestForRedirect(search) {
        const { makeSearchRequest, updateIsUrlRedirectLoading } = this.props;
        if (search) {
            updateIsUrlRedirectLoading(true);
            makeSearchRequest(search);
            this.setState({ activeSearch: search });
        }
    }

    handleRedirect() {
        const {
            hideActiveOverlay,
            onSearchBarChange,
            deactivateSearchBar,
            redirectUrl,
            clearRedirectUrl,
            clearSearchResults,
            updateIsUrlRedirectLoading
        } = this.props;
        const { activeSearch } = this.state;
        const redirect = redirectUrl ? removeBaseUrl(redirectUrl) : `/search/${ activeSearch }`;
        history.push(appendWithStoreCode(redirect));
        hideActiveOverlay();
        clearSearchResults();
        onSearchBarChange({ target: { value: '' } });
        deactivateSearchBar();
        clearRedirectUrl();
        updateIsUrlRedirectLoading(false);
        this.setState({ scrollTop: true });
        if (isScrollDisabled()) {
            toggleScroll(true);
        }
    }

    render() {
        const { isSkeleton } = this.props;

        if (isSkeleton) {
            return <TextPlaceholder height="button" length="button" />;
        }

        return (
            <SearchField
              { ...this.containerFunctions }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
            />
        );
    }
}

export default withReducers({
    SearchBarReducer
})(connect(mapStateToProps, mapDispatchToProps)(SearchFieldContainer));
