/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * Event names
 *
 * @type {string}
 */
export const EVENT_GTM_CHECKOUT = 'gtm_checkout';
export const EVENT_GTM_CHECKOUT_OPTION = 'gtm_checkout_option';
export const EVENT_GTM_IMPRESSIONS = 'gtm_impressions';
export const EVENT_GTM_IMPRESSIONS_PLP = 'gtm_impressions_plp';
export const EVENT_GTM_IMPRESSIONS_HOME = 'gtm_impressions_home';
export const EVENT_GTM_IMPRESSIONS_CROSS_SELL = 'gtm_impressions_cross_sell';
export const EVENT_GTM_IMPRESSIONS_WISHLIST = 'gtm_impressions_wishlist';
export const EVENT_GTM_IMPRESSIONS_SEARCH = 'gtm_impressions_search';
export const EVENT_GTM_IMPRESSIONS_LINKED = 'gtm_impressions_linked';
export const EVENT_GTM_GENERAL_INIT = 'gtm_general_init';
export const EVENT_GTM_PRODUCT_ADD_TO_CART = 'gtm_product_add_to_cart';
export const EVENT_GTM_PRODUCT_REMOVE_FROM_CART = 'gtm_product_remove_from_cart';
export const EVENT_GTM_PRODUCT_CLICK = 'gtm_product_click';
export const EVENT_GTM_PRODUCT_DETAIL = 'gtm_product_detail';
export const EVENT_GTM_PURCHASE = 'gtm_purchase';
export const EVENT_GTM_USER_LOGIN = 'gtm_user_login';
export const EVENT_GTM_USER_REGISTER = 'gtm_user_register';
export const EVENT_GTM_NOT_FOUND = 'gtm_not_found';
export const EVENT_GTM_SITE_SEARCH = 'gtm_site_search';
export const EVENT_GTM_SITE_SEARCH_STARTED = 'gtm_site_search_started';
export const EVENT_GTM_RESET_ECOMMERCE = 'gtm_reset_ecommerce';
export const EVENT_GTM_DYNAMIC_RETARGETING = 'gtm_dynamic_retargeting';
export const EVENT_GTM_CATALOG_CATEGORY_VIEW = 'catalog_category_view';
export const EVENT_WELCOME_PAGE_VISIBLE = 'welcome_page_visible';
export const EVENT_WELCOME_PAGE_NOT_VISIBLE = 'welcome_page_not_visible';
export const EVENT_GTM_CMS_PAGE_VIEW = 'cms_page_view';

export const EVENT_WELCOME_PAGE_REDIRECT = 'welcome_page_redirect';
export const EVENT_WELCOME_PAGE_OTHER_REDIRECT = 'welcome_page_other_redirect';
export const EVENT_WELCOME_PAGE_NO_REDIRECT = 'welcome_page_no_redirect';

export const EVENT_TRANSLATE_PAGE = 'translate_page';

export const BOD_CUSTOM_EVENTS = [
    EVENT_WELCOME_PAGE_NOT_VISIBLE,
    EVENT_WELCOME_PAGE_VISIBLE,
    EVENT_WELCOME_PAGE_REDIRECT,
    EVENT_WELCOME_PAGE_OTHER_REDIRECT,
    EVENT_WELCOME_PAGE_NO_REDIRECT,
    EVENT_TRANSLATE_PAGE,
    EVENT_GTM_CATALOG_CATEGORY_VIEW,
    EVENT_GTM_CMS_PAGE_VIEW
];

export const EVENT_KEY_GENERAL = 'general';
export const EVENT_KEY_USER_LOGIN = 'userLogin';
export const EVENT_KEY_USER_REGISTER = 'userRegister';
export const EVENT_KEY_ADD_TO_CART = 'addToCart';
export const EVENT_KEY_PRODUCT_CLICK = 'productClick';
export const EVENT_KEY_PRODUCT_DETAIL = 'productDetail';
export const EVENT_KEY_PRODUCT_REMOVE_FROM_CART = 'removeFromCart';
export const IMPRESSIONS = 'impression';
export const EVENT_KEY_NOT_FOUND = 'notFound';
export const EVENT_KEY_CHECKOUT_OPTION = 'checkoutOption';
export const EVENT_KEY_CHECKOUT = 'checkout';
export const EVENT_KEY_PURCHASE = 'purchase';
export const EVENT_KEY_SEARCH = 'siteSearch';
export const EVENT_KEY_SEARCH_STARTED = 'siteSearchStarted';
