import PropTypes from 'prop-types';

import IconComponent from 'Component/Icon';
/** @namespace Bodypwa/Component/PreloaderIcon/Component */
export class PreloaderIconComponent extends IconComponent {
    static propTypes = {
        size: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        className: PropTypes.string
    };

    render() {
        const { dimensions, colors } = this;
        const { size, color, className } = this.props;
        return (
            <svg
              className={ className }
              width={ dimensions[ size ] }
              height={ dimensions[ size ] }
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
            <circle cx="12" cy="12" r="10" stroke="white" stroke-opacity="0.5" stroke-width="2.5" />
            <path
              d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715
             2 12 2"
              stroke={ colors[ color ] }
              stroke-width="2.5"
              className="icon-color"
            />
            </svg>

        );
    }
}
export default PreloaderIconComponent;
