/* eslint-disable react/destructuring-assignment */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import PropTypes from 'prop-types';
import React from 'react';

import Html from 'Component/Html';
/** @namespace Bodypwa/Component/HtmlCode/Component */
export class HtmlCodeComponent extends React.PureComponent {
    static propTypes = {
        elements: PropTypes.shape({
            BaseHtmlCode: PropTypes.shape({
                childEleBag: PropTypes.arrayOf(
                    PropTypes.oneOfType([
                        PropTypes.string, // Dacă este un string HTML
                        PropTypes.node // Sau un nod React
                    ])
                ).isRequired,
                Ele: PropTypes.elementType.isRequired // Componenta utilizată pentru redare
            }).isRequired
        }).isRequired
    };

    render() {
        const {
            BaseHtmlCode
        } = this.props.elements;

        const html = BaseHtmlCode.childEleBag[0];
        const { origin } = window.location;

        return (
            <BaseHtmlCode.Ele>
                { !!html && typeof html === 'string' ? <Html content={ html.replace(origin, '') } /> : html }
            </BaseHtmlCode.Ele>
        );
    }
}

export default HtmlCodeComponent;
