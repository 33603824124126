import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';
import { promotions_top_block } from 'Component/PromotionsTopBlock/PromotionsTopBlock.config';
import { ChildrenType } from 'Type/Common.type';

import './CmsBlock.style';
import './CmsBlock.override.style';
/** @namespace Bodypwa/Component/CmsBlock/Component */
export class CmsBlockComponent extends PureComponent {
    static propTypes = {
        cmsBlock: PropTypes.shape({
            identifier: PropTypes.string,
            content: PropTypes.string,
            disabled: PropTypes.bool
        }),
        children: ChildrenType,
        id: PropTypes.string,
        baseUrl: PropTypes.string.isRequired,
        className: PropTypes.string
    };

    static defaultProps = {
        cmsBlock: {},
        children: [],
        id: '',
        className: ''
    };

    renderPlaceholder() {
        const {
            children
        } = this.props;

        if (children.length) {
            return children;
        }

        return null;
    }

    renderCmsPlaceholder() {
        return (
            <div block="Placehoolder" elem="Container">
                <div block="Placehoolder" elem="InnerContainer">
                    <div block="Placehoolder" elem="SectionPlaceholder" mods={ { promotions_top_block: true } } />
                </div>
            </div>
        );
    }

    render() {
        const {
            cmsBlock: {
                identifier,
                content,
                disabled
            },
            id,
            baseUrl,
            className
        } = this.props;

        // TODO MAKE COMPONENT TO HANDLE CMS BLOCK PRELOADERS
        if (id === promotions_top_block && !content) {
            return this.renderCmsPlaceholder();
        }

        if (disabled) {
            return null;
        }

        if (identifier === undefined) {
            return this.renderPlaceholder();
        }

        return (
            <div
              className={ ` ${className}` }
            >
                <Html content={ content } baseUrl={ baseUrl } />
            </div>
        );
    }
}

export default CmsBlockComponent;
