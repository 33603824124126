import PropTypes from 'prop-types';

import IconComponent from 'Component/Icon';
/** @namespace Bodypwa/Component/CrossIcon/Component */
export class CrossIconComponent extends IconComponent {
    static propTypes = {
        size: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        className: PropTypes.string
    };

    static defaultProps = {
      className: ''
    };

    render() {
        const { size, color, className } = this.props;
        const { dimensions, colors } = this;

        return (
            <svg
              className={ className }
              width={ dimensions[ size ] }
              height={ dimensions[ size ] }
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path
                  d="M5.76783 4L12.0001 10.2323L18.2322 4.00013L20 5.7679L13.7679 12L20 18.2321L18.2321
                20L12.0001 13.7678L5.76784 20.0001L4 18.2322L10.2323 12L4.00006 5.76777L5.76783 4Z"
                  fill={ colors[ color ] }
                  className={ `icon-color ${className}` }
                />
            </svg>
        );
    }
}
export default CrossIconComponent;
