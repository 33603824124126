/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable consistent-return */
// Disabled due `domToReact` internal logic
import { isNotEmptyArr, makeId } from '@scandipwa/page-builder/src/helper/functions';
import parser from 'html-react-parser';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';
import PropTypes from 'prop-types';
import {
    createElement, createRef,
    forwardRef, PureComponent, Suspense
} from 'react';

import Buttons from 'Component/Buttons/Buttons.component';
import { BUTTONS_SKELETON } from 'Component/Buttons/Buttons.config';
import CookieScriptPage from 'Component/CookieScriptPage';
import Dotdigital from 'Component/DotdigitalForm';
import { DOTDIGITAL_FORM_SKELETON } from 'Component/DotdigitalForm/Dotdigital.config';
import DynamicBlock from 'Component/DynamicBlock';
import { DYNAMIC_BLOCK_SKELETON } from 'Component/DynamicBlock/DynamicBlock.config';
import { SLIDER_SKELETON } from 'Component/Html/Html.config';
import HtmlCode from 'Component/HtmlCode';
import { HTML_CODE_SKELETON } from 'Component/HtmlCode/HtmlCode.config';
import Image from 'Component/Image';
import LazyBackground from 'Component/LazyBackground';
import Link from 'Component/Link';
// import Loader from 'Component/Loader/Loader.component';
import PrivacyPolicyButton from 'Component/PrivacyPolicyButton';
import SkeletonComponent from 'Component/Skeleton/Skeleton.component';
import Tab from 'Component/Tab';
import { TAB_SKELETON } from 'Component/Tab/Tab.config';
import { lazyComponentLoader } from 'Util/lazyComponentLoader';
import { hash } from 'Util/Request/Hash';

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/create-config-files
export const PRIVACY_POPUP_MAGIC_LINK = '#amasty-scandipwa-gdpr-popup-magic-link';

export const Slider = lazyComponentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "slider" */
    'Component/Slider'
), 2);

export const WidgetFactory = lazyComponentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget" */
    'Component/WidgetFactory'
), 2);

export const SLIDER_SKELETON_OLD = [{
    name: 'BaseSlider',
    type: 'div',
    isLoopParent: true,
    children: [
        { name: 'Slide', type: 'div' }
    ]
}];

/** @namespace Bodypwa/Component/Html/Component/decodeUrl */
export function decodeUrl(value) {
    let result = '';
    const decodedValue = decodeURIComponent((value).replace(window.location.href, ''));
    const regexp = /{{.*\s*url="?(.*\.([a-z|A-Z]*))"?\s*}}/;
    if (regexp.test(decodedValue)) {
        const [, url] = regexp.exec(decodedValue);
        result = `media/${ url}`;
    }

    return result;
}
/**
 * Html content parser
 * Component converts HTML strings to React components
 * @class Html
 * @namespace Bodypwa/Component/Html/Component */
export class HtmlComponent extends PureComponent {
    static propTypes = {
        content: PropTypes.string.isRequired
    };

    originalMember;

    baseInstance;

    sliderRef = createRef();

    parserOptions;

    skeletons = {
        CatalogProductList: SLIDER_SKELETON
    };

    createdOutsideElements = {};

    rules = [
        {
            query: { dataCookiescriptreport: 'report' },
            replace: this.replaceCookieScriptPage
        },
        {
            query: { name: ['widget'] },
            replace: this.replaceWidget
        },
        {
            query: { name: ['a'] },
            replace: this.replaceLinks
        },
        {
            query: { name: ['input'] },
            replace: this.replaceInput
        },
        {
            query: { name: ['script'] },
            replace: this.replaceScript
        },
        {
            query: { name: ['style'] },
            replace: this.replaceStyle
        },
        {
            query: { name: ['table'] },
            replace: this.wrapTable
        },
        {
            query: { name: ['img'] },
            replace: this.replaceImages
        },
        { query: { dataContentType: 'buttons' }, replace: this.replaceButtons },
        { query: { dataContentType: 'tabs' }, replace: this.replaceTab },
        { query: { dataContentType: 'slider' }, replace: this.replaceSlider },
        { query: { dataContentType: 'dynamic_block' }, replace: this.replaceDynamicBlock },
        { query: { dataContentType: 'dotdigitalgroup_form' }, replace: this.replaceDotdigitalForm },
        { query: { cssClass: 'cms-draggable-scroll' }, replace: this.enableDragable },
        { query: { dataContentType: 'html' }, replace: this.replaceHtmlCode },
        { query: { dataBackgroundImages: '{}' }, replace: this.handleDynamicBackgroundImages }
    ];

    parserOptions = {
        replace: (domNode) => {
            if (domNode.data && !domNode.data.replace(/\u21b5/g, '').replace(/\s/g, '').length) {
                return;
            }

            const { name: domName, attribs: domAttrs } = domNode;

            const rule = this.rules.find((rule) => {
                const {
                    query: {
                        dataContentType, attribs, cssClass, name, dataBackgroundImages, dataCookiescriptreport
                    }
                } = rule;

                if (dataCookiescriptreport && domAttrs
                    && domAttrs['data-cookiescriptreport'] === dataCookiescriptreport) {
                    return true;
                }

                if (cssClass && domNode.attribs
                    && domNode.attribs.class && domNode.attribs.class.indexOf(cssClass) !== -1) {
                    return true;
                }

                if (dataContentType && domAttrs
                    && domAttrs['data-content-type'] === dataContentType) {
                    return true;
                }

                if (name && domName && name.indexOf(domName) !== -1) {
                    return true;
                }

                if (dataBackgroundImages && domAttrs && domAttrs['data-background-images']) {
                    return true;
                }
                if (attribs && domAttrs) {
                    // eslint-disable-next-line fp/no-loops, fp/no-let
                    for (let i = 0; i < attribs.length; i++) {
                        const attrib = attribs[i];

                        if (typeof attrib === 'object') {
                            const queryAttrib = Object.keys(attrib)[0];

                            if (Object.prototype.hasOwnProperty.call(domAttrs, queryAttrib)) {
                                return domAttrs[queryAttrib].match(Object.values(attrib)[0]);
                            }
                        } else if (Object.prototype.hasOwnProperty.call(domAttrs, attrib)) {
                            return true;
                        }
                    }
                }

                return false;
            });

            if (rule) {
                const { replace } = rule;
                return replace.call(this, domNode);
            }
        },
        trim: true
    };

    componentDidMount() {
        // Object to store multiple refs
        Object.keys(this.dynamicRefs).forEach((key) => {
            const ref = this.dynamicRefs[key];
            if (ref.current) {
                this.dragElement(ref.current);
            }
        });
    }

    componentDidUpdate() {
        Object.keys(this.dynamicRefs).forEach((key) => {
            const ref = this.dynamicRefs[key];

            if (ref.current && !ref.current._eventHandlers) {
                this.dragElement(ref.current);
            }
        });
    }

    componentWillUnmount() {
        // Remove event listeners from each slider
        Object.keys(this.dynamicRefs).forEach((key) => {
            const ref = this.dynamicRefs[key];
            if (ref.current) {
                const slider = ref.current;
                const {
                    mouseDownHandler, mouseUpHandler, mouseLeaveHandler, mouseMoveHandler
                } = slider._eventHandlers;

                slider.removeEventListener('mousedown', mouseDownHandler);
                slider.removeEventListener('mouseup', mouseUpHandler);
                slider.removeEventListener('mouseleave', mouseLeaveHandler);
                slider.removeEventListener('mousemove', mouseMoveHandler);
            }
        });

        document.getElementById('EmbedSocialHashtagScript')?.remove();
    }

    __construct(props) {
        super.__construct(props);

        // Object to store multiple refs
        this.dynamicRefs = {};

        // Example state, extend as needed
        this.state = {
            sliderSet: false
        };
    }

    createRef(key) {
        if (!this.dynamicRefs[key]) {
            this.dynamicRefs[key] = createRef();
        }

        return this.dynamicRefs[key];
    }

    enableDragable(domNode) {
        const { children, attribs } = domNode;
        // eslint-disable-next-line no-magic-numbers
        const refKey = attribs.id || `ref-${makeId(5)}`; // Ensure unique ref key

        return (
            <div
              ref={ this.createRef(refKey) }
              { ...attributesToProps(attribs) }
            >
                { domToReact(children, this.parserOptions) }
            </div>
        );
    }

    dragElement(slider) {
        let isDown = false;
        let startX;
        let scrollLeft;

        const mouseDownHandler = (e) => {
            e.preventDefault();
            const { scrollLeft: initialScollLeft } = slider;
            isDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = initialScollLeft;
        };

        const mouseUpHandler = (e) => {
            e.preventDefault();
            isDown = false;
            setTimeout(() => {
                slider.classList.remove('active');
            // eslint-disable-next-line no-magic-numbers
            }, 200);
        };

        const mouseLeaveHandler = () => {
            isDown = false;
            slider.classList.remove('active');
        };

        const mouseMoveHandler = (e) => {
            if (!isDown) {
                return;
            }
            slider.classList.add('active');
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            // eslint-disable-next-line no-magic-numbers
            const walk = (x - startX) * 3; // scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        };

        slider.addEventListener('mousedown', mouseDownHandler);
        slider.addEventListener('mouseup', mouseUpHandler);
        slider.addEventListener('mouseleave', mouseLeaveHandler);
        slider.addEventListener('mousemove', mouseMoveHandler);

        // Store references to the event handlers on the slider element
        slider._eventHandlers = {
            mouseDownHandler,
            mouseUpHandler,
            mouseLeaveHandler,
            mouseMoveHandler
        };
    }

    replaceSpecialDomAttrs(domNode) {
        const { attribs: domAttrs } = domNode;
        if (!domAttrs || Object.keys(domAttrs).length === 0) {
            return;
        }
        if (domAttrs['data-background-images']) {
            this.handleDynamicBackgroundImages(domAttrs, domNode);
        }
    }
    // { attribs, children }

    // Magento page-builder is using the below objects:
    // {"desktop_image": "http://host/media/wysiwyg/background.jpg", "mobile_image": "http://host/media/wysiwyg/banner-1.jpg"}
    // OR {"desktop_image": "{{media url=wysiwyg/wide-banner-background.jpg}}"}
    // to generate 2 unique classnames for desktop & mobile.
    // Let just generate 1 unique classname and use media-query for mobile
    handleDynamicBackgroundImages(domNode) {
        const { attribs: domAttrs } = domNode;

        const images = JSON.parse(domAttrs['data-background-images'].replace(/\\(.)/mg, '$1')) || {};
        // eslint-disable-next-line no-magic-numbers
        const uniqClassName = `bg-image-${makeId(5)}`;

        const desktopBg = images.desktop_image ? (decodeUrl(images.desktop_image) || images.desktop_image) : null;
        const mobileBg = images.mobile_image ? (decodeUrl(images.mobile_image) || images.mobile_image) : null;
        return (
            <LazyBackground
              desktopBg={ desktopBg }
              mobileBg={ mobileBg }
              uniqClassName={ uniqClassName }
              { ...attributesToProps(domAttrs) }
            >
                { domToReact(domNode.children, this.parserOptions) }
            </LazyBackground>
        );
    }

    decodeHTMLEntities(text) {
        const txt = document.createElement('textarea');
        txt.innerHTML = text;
        return txt.value;
    }

    // options obj: {isInLoop: boolean, allowedTypes: ('tag'|'script'|'style')[]}.
    // The idea is:
    // - For individual element, we create a React Element and store all of its props.
    // - For in-loop elements (data.map(() => <div />). We create just the first element
    // then store all of its sibling's props to a bag.
    // The result is we will have the same HTML structure in React Element. So that we can
    // use React to manipulate these elements freely
    toReactElements(domNodes, skeleton, options = {}, res = {}) {
        const {
            isInLoop = false,
            // Sometimes, page-builder html code contains un-sanitize chars from script or style tags,
            // which makes our parser run incorrectly.
            // Most of the time, we don't need them so that we limit to "tag" by default
            allowedTypes = ['tag']
        } = options;
        let skeletonIdx = 0; // Index to help mapping current domNode with our skeleton config
        domNodes.forEach((domNode) => {
            if (allowedTypes.indexOf(domNode.type) === -1) {
                return;
            }

            let childData = null;
            let childEle = null;
            const config = skeleton[skeletonIdx] || skeleton[0];
            skeletonIdx += 1;

            const orgProps = this.attributesToProps(domNode.attribs || {});

            // Create element if not existed
            if (!res[config.name]) {
                const element = forwardRef(
                    ({ children, ...rest }, ref) => createElement(
                        domNode.name, { ...(!isInLoop && orgProps), ...rest, ref }, children
                    )
                );

                res[config.name] = {
                    Ele: element, propsBag: [], childData: [], childEleBag: []
                };
            }

            // Generate all children nodes if our skeleton reached the end
            // in order to render these children nodes later on
            if (!config.children && domNode.children) {
                childEle = domToReact(domNode.children, this.parserOptions);
            }

            if ((isInLoop || config.isLoopParent) && isNotEmptyArr(domNode.children)) {
                childData = domNode.children.map((i) => i.data);
            }

            res[config.name] = {
                ...res[config.name],
                propsBag: [...res[config.name].propsBag, orgProps],
                childEleBag: [...res[config.name].childEleBag, childEle],
                childData: [...res[config.name].childData, ...(childData || [])]
            };

            if (domNode.children && config.children) {
                const childRes = this.toReactElements(domNode.children, config.children, {
                    isInLoop: (isInLoop || config.isLoopParent),
                    allowedTypes
                }, res);

                res = { ...res, ...childRes };
            }
        });

        return res;
    }

    replaceTab(domNode) {
        return <Tab elements={ this.toReactElements([domNode], TAB_SKELETON) } />;
    }

    replaceSlider(domNode) {
        return <Slider elements={ this.toReactElements([domNode], SLIDER_SKELETON_OLD) } />;
    }

    replaceDynamicBlock(domNode) {
        return <DynamicBlock elements={ this.toReactElements([domNode], DYNAMIC_BLOCK_SKELETON) } />;
    }

    replaceDotdigitalForm(domNode) {
        return (
            <Dotdigital
              // eslint-disable-next-line max-len
              elements={ this.toReactElements([domNode], DOTDIGITAL_FORM_SKELETON, { allowedTypes: ['tag', 'script'] }) }
            />
        );
    }

    replaceCookieScriptPage(domNode) {
        return (
            <CookieScriptPage
                // eslint-disable-next-line max-len
              elements={ domNode }
            />
        );
    }

    replaceHtmlCode(domNode) {
        const { baseUrl } = domNode;
        return (
          <HtmlCode
            baseUrl={ baseUrl }
            elements={ this.toReactElements([domNode], HTML_CODE_SKELETON,
                 { allowedTypes: ['tag', 'script', 'style'] }) }
          />
        );
    }

    replaceButtons(domNode) {
        return <Buttons elements={ this.toReactElements([domNode], BUTTONS_SKELETON) } />;
    }

    /**
     * Class method that replaces a "magic link" with a PrivacyPolicyButton.
     *
     * @param {Object} parserOptions - Options used by domToReact.
     * @returns {Function} A function that receives { attribs, children }.
     */
    replacePrivacyPolicyButton(parserOptions) {
        const instance = this;
        return ({ attribs, children }) => {
            const { href } = attribs;
            const { privacyPolicy } = instance.props;

            if (href === PRIVACY_POPUP_MAGIC_LINK) {
                return (
                <PrivacyPolicyButton privacyPolicy={ privacyPolicy }>
                    { domToReact(children, parserOptions) }
                </PrivacyPolicyButton>
                );
            }

            // Fallback to whatever default link replacement logic you have
            return instance.replaceLinks({ attribs, children });
        };
    }

    attributesToProps(attribs) {
        const toCamelCase = (string) => string.replace(/_[a-z]/g, (match) => match.substr(1).toUpperCase());

        const convertPropertiesToValidFormat = (properties) => Object.entries(properties)
            .reduce((validProps, [key, value]) => {
                // eslint-disable-next-line no-restricted-globals
                if (!isNaN(value)) {
                    return { ...validProps, [toCamelCase(key)]: +value };
                }

                return { ...validProps, [toCamelCase(key)]: value };
            }, {});

        const properties = convertPropertiesToValidFormat(attribs);

        return attributesToProps(properties);
    }

    /**
     * Replace links to native React Router links
     * @param  {{ attribs: Object, children: Array }}
     * @return {void|JSX} Return JSX if link is allowed to be replaced
     * @memberof Html
     */
    replaceLinks({ attribs, children }) {
        const { href, ...attrs } = attribs;
        const { baseUrl, privacyPolicy } = this.props;

        if (href === PRIVACY_POPUP_MAGIC_LINK) {
            return (
                <PrivacyPolicyButton privacyPolicy={ privacyPolicy }>
                    { domToReact(children, this.parserOptions) }
                </PrivacyPolicyButton>
            );
        }

        if (href) {
            const isAbsoluteUrl = (value) => new RegExp('^(?:[a-z]+:)?//', 'i').test(value);
            const isSpecialLink = (value) => new RegExp('^(sms|tel|mailto):', 'i').test(value);
            const isAnchor = (value) => new RegExp('^#').test(value);
            if (isAnchor(href)) {
                return (
                    // eslint-disable-next-line react/jsx-no-bind
                    <a { ...attributesToProps(attrs) } href={ href } onClick={ (e) => this.handleAnchorClick(e, href) }>
                        { domToReact(children, this.parserOptions) }
                    </a>
                );
            }

            if (isAbsoluteUrl(href) && baseUrl && href.includes(baseUrl)) {
                return (
                    <Link { ...attributesToProps({ ...attrs, to: href.replace(baseUrl, '') }) }>
                        { domToReact(children, this.parserOptions) }
                    </Link>
                );
            }

            if (!isAbsoluteUrl(href) && !isSpecialLink(href)) {
                return (
                    <Link { ...attributesToProps({ ...attrs, to: href }) }>
                        { domToReact(children, this.parserOptions) }
                    </Link>
                );
            }
        }
    }

    handleAnchorClick = (e, href) => {
        e.preventDefault();
        const id = href.substring(1);
        const element = document.getElementById(id);

        const easeInOutQuad = (t, b, c, d) => {
            t /= d / 2;
            if (t < 1) {
                return (c / 2) * t * t + b;
            }
            t--;
            return (-c / 2) * (t * (t - 2) - 1) + b;
        };
        const smoothScrollToElement = (element) => {
            const duration = 500; // Adjust as needed
            const startPosition = window.pageYOffset;
            const targetPosition = element.getBoundingClientRect().top + window.pageYOffset;

            let start = null;
            function step(timestamp) {
                if (!start) {
                    start = timestamp;
                }
                const progress = timestamp - start;
                window.scrollTo(0, easeInOutQuad(progress, startPosition, targetPosition, duration));
                if (progress < duration) {
                    window.requestAnimationFrame(step);
                }
            }
            window.requestAnimationFrame(step);
        };

        if (element) {
            if ('scrollBehavior' in document.documentElement.style) {
                // Use polyfill for smooth scrolling
                element.scrollIntoView({ behavior: 'smooth', alignToTop: true });
            } else {
                smoothScrollToElement(element);
            }
        }
    };

    /**
     * Replace img to React Images
     * @param  {{ attribs: Object }}
     * @return {void|JSX} Return JSX with image
     * @memberof Html
     */
    replaceImages({ attribs }) {
        const attributes = attributesToProps(attribs);

        if (attribs.src) {
            return <Image { ...attributes } isPlain />;
        }
    }

    /**
     * Replace input.
     * @param  {{ attribs: Object }}
     * @return {void|JSX} Return JSX with image
     * @memberof Html
     */
    replaceInput({ attribs }) {
        return <input { ...attributesToProps(attribs) } />;
    }

    /**
     * Wrap table in container
     *
     * @param attribs
     * @param children
     * @returns {*}
     */
    wrapTable({ attribs, children }) {
        return (
            <div block="Table" elem="Wrapper">
                <table { ...attributesToProps(attribs) }>
                    { domToReact(children, this.parserOptions) }
                </table>
            </div>
        );
    }

    /**
     * Insert corresponding widget
     *
     * @param {{ attribs: Object }} { attribs }
     * @returns {null|JSX} Return Widget
     * @memberof Html
     */
    replaceWidget({ attribs }) {
          const { type } = attribs;

        return (
            <Suspense fallback={ <SkeletonComponent items={ this.skeletons[type] || [] } /> }>
                <WidgetFactory { ...this.attributesToProps(attribs) } />
            </Suspense>
        );
    }

    replaceStyle(elem) {
        const { children } = elem;
        // eslint-disable-next-line fp/no-let
        let elementData = '';
        children.forEach((child) => {
            if (child.type === 'text') {
                elementData = `${elementData}${child.data}`;
            }
        });
        // eslint-disable-next-line no-undef
        const elemHash = hash(elementData);
        if (this.createdOutsideElements[elemHash]) {
            return;
        }
        const style = document.createElement('style');
        if (children && children[0]) {
            style.appendChild(document.createTextNode(children[0].data));
        }
        document.head.appendChild(style);
        this.createdOutsideElements[elemHash] = true;
    }

    replaceScript(elem) {
        const { attribs, children } = elem;
        const elemHash = hash(elem);

        if (this.createdOutsideElements[elemHash]) {
            return;
        }

        const script = document.createElement('script');

        Object.entries(attribs).forEach(([attr, value]) => script.setAttribute(attr, value));

        if (children && children[0]) {
            script.appendChild(document.createTextNode(children[0].data));
        }

        document.head.appendChild(script);

        this.createdOutsideElements[elemHash] = true;
    }

    render() {
        const { content } = this.props;
        return parser(content, this.parserOptions);
    }
}

export default HtmlComponent;
