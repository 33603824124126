import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

/** @namespace Bodypwa/Component/Skeleton/Component */
export class SkeletonComponent extends PureComponent {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.shape({
          type: PropTypes.oneOf(['rect', 'circle', 'text']),
          width: PropTypes.string,
          maxWidth: PropTypes.string,
          height: PropTypes.string,
          style: PropTypes.objectOf(PropTypes.string),
          className: PropTypes.string
        }),
        PropTypes.shape({
          type: PropTypes.oneOf(['group']),
          items: PropTypes.arrayOf(PropTypes.object),
          columns: PropTypes.number,
          maxWidth: PropTypes.string,
          gap: PropTypes.string,
          width: PropTypes.string,
          className: PropTypes.string,
          alignItems: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'stretch', 'baseline'])
        })
      ])
    ),
    gap: PropTypes.string,
    containerClassName: PropTypes.string,
    width: PropTypes.string,
    borderBottom: PropTypes.string
  };

  static defaultProps = {
    items: [{
        type: 'rect', width: '100%', height: '432px', maxWidth: '100%', marginBottom: '0'
    }],
    gap: '0.5rem',
    width: '100%',
    containerClassName: '',
    borderBottom: 'none'
  };

  renderSkeletonItem(item, key) {
    const {
        type = 'rect', width, height, style, className, maxWidth, slide, marginBottom
    } = item;

    const computedStyle = {
      width,
      height,
      maxWidth,
      marginBottom,
      ...style
    };

    const circleStyles = type === 'circle' ? {
        borderRadius: '50%',
        width: width || height,
        height: height || width
    } : {};

    const itemClassName = `skeleton ${className || ''}`;

    return (
      <div
        key={ key }
        className={ itemClassName }
        style={ { ...computedStyle, ...circleStyles, flexShrink: slide ? 0 : 1 } }
      />
    );
  }

  renderGroup(item, key) {
    const {
        items: groupItems,
        gap: groupGap,
        width,
        className,
        flexDirection,
        maxWidth,
        alignItems,
        slider,
        columns,
        slide,
        marginBottom,
        height,
        style,
        justifyContent
    } = item;

    const groupStyle = {
      display: 'flex',
      gap: groupGap || 0,
      width: width || '100%',
      height: height || 'auto',
      maxWidth: maxWidth || '100%',
      alignItems: alignItems || 'center',
      justifyContent: justifyContent || alignItems || 'left',
      flexDirection: flexDirection || 'row',
      marginBottom: marginBottom || '0',
      flexShrink: slide ? 0 : 1,
      ...style
    };
    const groupColumns = [];

    if (columns) {
        for (let i = 0; i < columns; i++) {
            groupColumns.push(groupItems[0]);
        }
    }

    const children = (columns ? groupColumns : groupItems)
                        .map((child, index) => this.renderSkeletonContent(child, `${key}-${index}`));

    if (slider) {
        const { gap, ...restStyle } = groupStyle;
        const sliderStyle = {
            overflowY: 'hidden',
            marginLeft: 'auto',
            marginRight: 'auto',
            position: 'relative'
        };

        return (
            <div
              key={ key }
              style={ { ...restStyle, ...sliderStyle } }
              className={ `${className} overflow-y-hidden relative mx-auto` }
            >
                <div
                  style={ { gap } }
                  className="justify-flex-start flex relative w-full h-full overflow-x-scroll overflow-y-hidden"
                >
                    { children }
                </div>
            </div>
        );
    }

    return (
      <div key={ key } style={ groupStyle } className={ className }>
        { children }
      </div>
    );
  }

  renderSkeletonContent(item, key, slider = false) {
    if (item && item.type === 'group') {
      return this.renderGroup(item, key, slider);
    }

    return this.renderSkeletonItem(item, key, slider);
  }

  render() {
    const {
        items, gap, width, containerClassName, borderBottom
    } = this.props;
    const containerStyle = {
      display: 'flex',
      flexDirection: 'column',
      gap,
      width,
      flexWrap: 'wrap',
      borderBottom
    };

    return (
      <div className={ containerClassName } style={ containerStyle }>
        { items.map((item, index) => this.renderSkeletonContent(item, index)) }
      </div>
    );
  }
}

export default SkeletonComponent;
