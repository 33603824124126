/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import parser from 'html-react-parser';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { createPortal } from 'react-dom';
/** @namespace Bodypwa/Component/Meta/Component */
export class MetaComponent extends PureComponent {
    static propTypes = {
        metadata: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                property: PropTypes.string,
                content: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number
                ])
            })
        ).isRequired,
        canonical_url: PropTypes.string,
        default_title: PropTypes.string.isRequired,
        title_prefix: PropTypes.string.isRequired,
        title_suffix: PropTypes.string.isRequired,
        // title: MetaTitleType
        seo_markup: PropTypes.shape({
            social_markup: PropTypes.string,
            rich_snippets: PropTypes.objectOf(PropTypes.string)
        }),
        title: PropTypes.shape({
            value: PropTypes.string
        }),
        hreflangs: PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string,
                code: PropTypes.string
            })
        )
    };

    static defaultProps = {
        canonical_url: '',
        hreflangs: [],
        seo_markup: {
            social_markup: '',
            rich_snippets: {}
        },
        title: { value: '' }
    };

    componentDidMount() {
        // Remove prerendered meta tags so dynamic meta tags can take effect
        Array.prototype.slice.call(
            document.head.querySelectorAll('title[data-prerendered], meta[data-prerendered]')
        ).forEach((tag) => {
            document.head.removeChild(tag);
        });
    }

    renderTitle() {
        const {
            default_title,
            title_prefix,
            title_suffix,
            title
        } = this.props;

        const titlePrefix = title_prefix ? `${ title_prefix } | ` : '';
        const titleSuffix = title_suffix ? ` | ${ title_suffix }` : '';
        const { value = title } = title;

        return (
            <title>
                { `${ titlePrefix }${ value || default_title }${ titleSuffix }` }
            </title>
        );
    }

    renderAlternateLinks() {
        const { hreflangs } = this.props;

        if (!hreflangs) {
            return null;
        }

        return hreflangs.map((store) => {
            const {
                url = null,
                code = null
            } = store;

            return (
                <link
                  key={ code }
                  href={ url }
                  hrefLang={ code }
                  rel="alternate"
                />
            );
        }).reverse();
    }

    renderRichSnipet() {
        const {
            seo_markup: {
                social_markup, rich_snippets
            } = {}
        } = this.props;

        return (
            <>
                { Object.keys(rich_snippets || {}).map((item) => parser(rich_snippets[item] || '')) }
                { parser(social_markup || '') }
            </>
        );
    }

    renderCanonical() {
        const { canonical_url } = this.props;

        if (!canonical_url) {
            return null;
        }

        return (
            <link rel="canonical" href={ canonical_url } />
        );
    }

    renderMeta() {
        const { metadata } = this.props;

        return (
            <>
                { this.renderTitle() }
                { this.renderCanonical() }
                { this.renderAlternateLinks() }
                { this.renderRichSnipet() }
                { metadata.map((tag) => {
                    const {
                        name = null,
                        property = null,
                        content = null
                    } = tag;

                    return (
                        <meta
                          key={ name || property || 'meta' }
                          name={ name }
                          content={ content }
                        />
                    );
                }) }
            </>
        );
    }

    render() {
        return createPortal(
            { ...this.renderMeta() },
            document.head
        );
    }
}

export default MetaComponent;
