import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { MixType } from 'Type/Common.type';

import './TextPlaceholder.style';
/** @namespace Bodypwa/Component/TextPlaceholder/Component */
export class TextPlaceholderComponent extends PureComponent {
    static propTypes = {
        content: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.number
        ]),
        length: PropTypes.oneOf([
            'block',
            'short',
            'medium',
            'long',
            'paragraph',
            'custom'
        ]),
        height: PropTypes.oneOf([
            'sub-title',
            'content',
            'paragraph',
            'filter',
            'icon',
            'button',
            'logo'
        ]),
        align: PropTypes.oneOf([
            'center',
            'right'
        ]),
        gap: PropTypes.oneOf([
            'small',
            'medium',
            'l',
            'xl',
            'xxl',
            'xxxl'
        ]),
        space: PropTypes.oneOf([
            'medium'
        ]),
        // eslint-disable-next-line react/boolean-prop-naming
        fill: PropTypes.bool,
        // eslint-disable-next-line react/boolean-prop-naming
        circle: PropTypes.bool,
        mix: MixType
    };

    static defaultProps = {
        content: '',
        length: 'short',
        mix: {},
        height: false,
        align: false,
        gap: false,
        space: false,
        fill: false,
        circle: false
    };

    // TODO implement logic
    render() {
        const {
            content, mix,
            length = false,
            height = false,
            align = false,
            gap = false,
            space = false,
            fill = false,
            circle = false
        } = this.props;

        if (content) {
            return content;
        }

        return (
            <span
              mix={ mix }
              block="TextPlaceholder"
              mods={ {
                  length, height, align, gap, space, fill, circle
              } }
            >
                        <span />
            </span>
        );
    }
}

export default TextPlaceholderComponent;
