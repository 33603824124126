/* eslint-disable max-len */
/* eslint-disable max-lines */
// /* eslint-disable max-lines */
// /* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { BLACK_KEY, WHITE_KEY } from 'Component/Icon/Icon.config';
import Link from 'Component/Link';
import PreloaderIcon from 'Component/PreloaderIcon';
import TextPlaceholder from 'Component/TextPlaceholder';

import {
    POSITION_LEADING,
    SIZE_LARGE, TEXT_CENTER,
    TYPE_PRIMARY, WIDTH_FIXED
} from './Button.config';

// import ChevronIcon from 'Component/ChevronIcon';
// import { BOTTOM } from 'Component/ChevronIcon/ChevronIcon.config';
// import ChevronIcon from '../ChevronIcon';
import './Button.style';
/** @namespace Bodypwa/Component/Button/Component */
export class ButtonComponent extends PureComponent {
    static propTypes = {
        text: PropTypes.string,
        type: PropTypes.string,
        width: PropTypes.string,
        children: PropTypes.element,
        size: PropTypes.string,
        isIconSmall: PropTypes.bool,
        isIcon: PropTypes.bool,
        isLoading: PropTypes.bool,
        isSkeleton: PropTypes.bool,
        isFocus: PropTypes.bool,
        isNoBorder: PropTypes.bool,
        isNoBackground: PropTypes.bool,
        position: PropTypes.string,
        isDisabled: PropTypes.bool,
        onClick: PropTypes.func,
        actionType: PropTypes.string,
        isLink: PropTypes.bool,
        goTo: PropTypes.string,
        alignContent: PropTypes.string,
        className: PropTypes.string,
        onMouseEnterNext: PropTypes.func,
        onMouseLeaveNext: PropTypes.func,
        onMouseEnterPrev: PropTypes.func,
        onMouseLeavePrev: PropTypes.func,
        btnImage: PropTypes.element,
        id: PropTypes.string,
        isActive: PropTypes.string
    };

    static defaultProps = {
        text: '',
        type: TYPE_PRIMARY,
        width: WIDTH_FIXED,
        children: null,
        size: SIZE_LARGE,
        isIconSmall: false,
        isIcon: false,
        isLoading: false,
        isSkeleton: false,
        isFocus: false,
        isNoBorder: false,
        isNoBackground: false,
        position: POSITION_LEADING,
        isDisabled: false,
        onClick: () => {},
        actionType: 'submit',
        isLink: false,
        goTo: '',
        alignContent: TEXT_CENTER,
        className: '',
        onMouseEnterNext: () => {},
        onMouseLeaveNext: () => {},
        onMouseEnterPrev: () => {},
        onMouseLeavePrev: () => {},
        btnImage: null,
        id: '',
        isActive: false
    };

    buttonsTypeClass = {
        primary: {
            cssClass: 'py-4 px-6 font-bold text-black-normal hover:bg-yellow-500 active:bg-yellow-600 focus-visible:outline focus-visible:outline-4  focus-visible:outline-blue-400',
            background: 'bg-yellow-400',
            loading: 'bg-yellow-500 cursor-not-allowed pointer-events-none'

        },
        secondary: {
            cssClass: 'py-4 px-6 font-bold bg-black-normal text-white-normal hover:bg-grey-500 active:bg-grey-300 focus-visible:outline focus-visible:outline-4 focus-visible:outline-blue-400',
            loading: 'bg-grey-500 cursor-not-allowed pointer-events-none'
        },
        tertiary: {
            cssClass: 'py-4 px-6 font-bold border border-black-normal border-solid text-black-normal hover:shadow-[inset_0_0_0_1px_theme(colors.black-normal)] active:border-grey-300 active:shadow-[inset_0_0_0_1px_theme(colors.grey-300)] focus-visible:outline focus-visible:outline-4 focus-visible:outline-blue-400',
            loading: 'border-2 border-solid border-gray-300 cursor-not-allowed pointer-events-none'
        },
        text: {
            cssClass: 'group underline text-p16 text-black-normal hover:text-grey-500 active:text-grey-300 focus-visible:outline focus-visible:outline-4  focus-visible:outline-blue-400'
        },
        link: {
            cssClass: 'underline text-black-normal text-p16 hover:bg-yellow-400 active:bg-yellow-400 active:text-grey0500 focus-visible:outline focus-visible:outline-4  focus-visible:outline-blue-400'
        }
    };

    buttonsSiszeClass = {
        large: 'h-14 text-p16 font-bold',
        small: 'h-11 px-6 py-3 text-p14 font-bold',
        none: 'p-0 m-0'
    };

    buttonsWidthClass = {
        hug: 'w-auto whitespace-nowrap',
        fixed: 'w-[300px]',
        full: 'w-full'
    };

    buttonsPostionClass = {
        leading: 'gap-3',
        trailing: 'flex-row-reverse gap-3'
    };

    buttonsAlignClass = {
        left: 'justify-start',
        center: 'justify-center text-center'
    };

    isLoadingClass = ' cursor-not-allowed pointer-events-none';

    colors = {
        tertiary: BLACK_KEY,
        secondary: WHITE_KEY,
        primary: BLACK_KEY
    };

    renderButtonContent() {
        const {
            text,
            children,
            isLoading,
            btnImage,
            type
        } = this.props;

        if (isLoading) {
            return (
                <PreloaderIcon color={ this.colors[type] } className="animate-spin" />
            );
        }

        return (
            <>
                { btnImage || '' }
                { children }
                { text }
            </>
        );
    }

    render() {
        const {
            type,
            width,
            size,
            isIconSmall,
            isIcon,
            isLoading,
            isSkeleton,
            isFocus,
            isNoBorder,
            isNoBackground,
            position,
            isDisabled,
            onClick,
            actionType,
            isLink,
            goTo,
            alignContent,
            className,
            onMouseEnterNext,
            onMouseLeaveNext,
            onMouseEnterPrev,
            onMouseLeavePrev,
            id,
            isActive
        } = this.props;

        if (isSkeleton) {
            return (
                <div className="w-[300px] mt-6">
                    <TextPlaceholder height="button" length="button" />
                </div>
            );
        }

        const { cssClass, loading, background } = this.buttonsTypeClass[type];

        if (isLink) {
            return (
                <Link
                  block="Button"
                  className={ `inline-flex items-center duration-[.2s] ease-in-out cursor-pointer 
                    ${className}
                    ${cssClass}
                    ${this.buttonsWidthClass[width]}
                    ${this.buttonsSiszeClass[size]}
                    ${this.buttonsPostionClass[position]}
                    ${this.buttonsAlignClass[alignContent]}
                    ${isNoBackground ? 'bg-transparent' : background}
                    ${isNoBorder ? '!border-none' : ''}
                    ${isDisabled ? '!cursor-not-allowed !opacity-50' : ''}
                    ${isIcon ? '!p-4' : ''}
                    ${isIconSmall ? '!p-3' : ''}
                    ${isFocus ? 'focus:outline focus:outline-4 focus:outline-offset-4 focus:outline-red-400' : ''}
                    ${isLoading ? loading + this.isLoadingClass : ''}` }
                  to={ goTo }
                  onClick={ onClick }
                >
                      { this.renderButtonContent() }
                      { /* { this.renderWithImage() } */ }
                </Link>
            );
        }

        return (
            <button
              type={ actionType }
              id={ id }
              disabled={ isDisabled || isLoading }
              onClick={ onClick }
              onMouseEnterNext={ onMouseEnterNext }
              onMouseLeaveNext={ onMouseLeaveNext }
              onMouseEnterPrev={ onMouseEnterPrev }
              onMouseLeavePrev={ onMouseLeavePrev }
              className={ `inline-flex items-center duration-[.2s] ease-in-out cursor-pointer
                ${className}
                ${cssClass}
                ${this.buttonsWidthClass[width]} 
                ${this.buttonsPostionClass[position]} 
                ${this.buttonsSiszeClass[size]}
                ${this.buttonsAlignClass[alignContent]}
                ${isLoading ? loading + this.isLoadingClass : ''}
                ${isDisabled ? '!cursor-not-allowed !opacity-50' : ''}
                ${isFocus ? '!outline outline-4 outline-offset-4' : ''}
                ${isIcon ? '!p-4' : ''}
                ${isIconSmall ? '!p-3' : ''}
                ${isNoBorder ? '!border-none' : ''}
                ${isNoBackground ? 'bg-transparent' : background}
                ${ isActive}
                ` }

            >
                { this.renderButtonContent() }
            </button>
        );
    }
}
export default ButtonComponent;
