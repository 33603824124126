export const SLIDER_SKELETON = [
    {
        type: 'group',
        slider: true,
        columns: 10,
        gap: '1rem',
        items: [
            {
                type: 'group',
                maxWidth: 209,
                flexDirection: 'column',
                slide: true,
                gap: 8,
                alignItems: 'left',
                items: [
                    { height: 266 },
                    { height: 16 },
                    { height: 20 },
                    {
                        height: 20,
                        maxWidth: '50%',
                        style: { marginTop: -5 }
                    }
                ]
            }
        ]
    }
];
